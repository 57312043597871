import type { FC, ChangeEvent } from 'react';
import Typography from '@material-tailwind/react/components/Typography';
import { mdiEmailOutline, mdiKeyOutline } from '@mdi/js';
import CustomInput from '../../../../../components/CustomInput';
import WrapperMD from '../../../../../components/WrapperMD';

export interface SignUpViewProps {
  email: string;
  emailConfirm: string;
  password: string;
  isLoading?: boolean;
  error: {
    email: string;
    emailConfirm: string;
    password: string;
  };
  onChangeEmail: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeEmailConfirm: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ViewSignUp: FC<SignUpViewProps> = ({
  email,
  emailConfirm,
  password,
  error,
  isLoading = false,
  onChangeEmail,
  onChangeEmailConfirm,
  onChangePassword,
}): JSX.Element => (
  <>
    <Typography
      variant='h2'
      className='text-center py-4 px-0 font-gotham-bold  text-3xl 2xl:text-4xl'
    >
      Regístrate en SkyAlert
    </Typography>
    <Typography variant='lead' className='text-center text-lg 2xl:text-xl leading-tight mb-4'>
      <WrapperMD content="Ingresa con un correo electrónico para continuar con la compra de tu paquete <span className='font-gotham-bold text-[#F6D588]'>**GOLD**</span>**+**" />
    </Typography>
    <div className='flex flex-col mt-3'>
      <CustomInput
        id='email-register'
        type='text'
        label='Correo electrónico'
        placeholder='ejemplo@correo.com'
        pathIcon={mdiEmailOutline}
        isDisable={isLoading}
        error={error.email}
        onChange={onChangeEmail}
        value={email}
      />
      <CustomInput
        id='email-confirm-register'
        type='text'
        label='Confimar correo electrónico'
        placeholder='ejemplo@correo.com'
        styleContainer='mt-3'
        pathIcon={mdiEmailOutline}
        isDisable={isLoading}
        error={error.emailConfirm}
        onChange={onChangeEmailConfirm}
        value={emailConfirm}
      />
      <CustomInput
        id='password-register'
        type='password'
        label='Contraseña'
        placeholder='******'
        styleContainer='mt-3'
        pathIcon={mdiKeyOutline}
        isDisable={isLoading}
        error={error.password}
        onChange={onChangePassword}
        value={password}
      />
    </div>
  </>
);

ViewSignUp.defaultProps = {
  isLoading: false,
};

export default ViewSignUp;
