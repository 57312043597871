export const validateIsEmailPattern = (email: string): boolean => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  return regex.test(email);
};

export const validateIsFullNamePattern = (fullName: string): boolean => {
  const regex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
  return regex.test(fullName);
};

export const validateIsCompanyNamePattern = (companyName: string): boolean => {
  const regex = /^[a-zA-Z0-9À-ÿ\s]{1,50}$/;
  return regex.test(companyName);
};

export const validateIsPhonePattern = (phone: string): boolean => {
  const regex = /^(\+?\d{1,2}\s?)?[0-9]{10}$/;
  return regex.test(phone);
};
