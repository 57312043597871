import * as crypto from 'crypto-ts';
import LocalStorage from './LocalStorage';
import { StorageCryptoKey } from '../types';

const Storage = new LocalStorage('cryptoKey');

class EncryptionKeyManager {
  private static instance: EncryptionKeyManager;

  private encryptionKey: crypto.ɵe;

  private constructor() {
    this.encryptionKey = EncryptionKeyManager.loadEncryptionKey();
    this.storeEncryptionKey();
  }

  static getInstance(): EncryptionKeyManager {
    if (!EncryptionKeyManager.instance) {
      EncryptionKeyManager.instance = new EncryptionKeyManager();
    }
    return EncryptionKeyManager.instance;
  }

  private static generateNewKey(): crypto.ɵe {
    // 16 bytes for AES-128
    const key = crypto.lib.WordArray.random(16);
    return key;
  }

  private static loadEncryptionKey(): crypto.ɵe {
    const storedKey = Storage.getItem<StorageCryptoKey>();
    return storedKey ? JSON.parse(storedKey) : EncryptionKeyManager.generateNewKey();
  }

  getEncryptionKey(): crypto.ɵe {
    return this.encryptionKey;
  }

  generateAndSetNewKey(): void {
    this.encryptionKey = EncryptionKeyManager.generateNewKey();
    this.storeEncryptionKey();
  }

  private storeEncryptionKey(): void {
    Storage.setItem(JSON.stringify(this.encryptionKey));
  }

  encrypt<T>(data: T): string {
    const transformedData = crypto.AES.encrypt(
      crypto.enc.Utf8.parse(JSON.stringify(data)),
      this.encryptionKey,
    );
    return transformedData.toString();
  }

  decrypt<T>(data: string): T {
    const decryptedData = crypto.AES.decrypt(data, this.encryptionKey);
    return JSON.parse(decryptedData.toString(crypto.enc.Utf8));
  }
}

export default EncryptionKeyManager;
