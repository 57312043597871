import { useEffect, useState } from 'react';
import type { ToastOptions } from 'react-toastify';
import { useCtxUser } from '../contexts/dish/user';
import { useCtxToastify } from '../contexts/common/toastify';
import { validateSubscription } from '../services';
import * as Types from '../types';

interface UseSubscribed {
  isSubscribed: boolean;
  isLoading: boolean;
}

const CONFIG_TOAST_ALERT: ToastOptions = {
  toastId: 'auth-form-login',
};

export const useSubscribed = (): UseSubscribed => {
  const { showErrorToast } = useCtxToastify();
  const {
    information: { email },
  } = useCtxUser();

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleLoading = (value = false): void => {
    setIsLoading(value);
  };

  const checkStateLoading = (): void => {
    if (!isLoading) {
      toggleLoading(true);
    }
  };

  const fetchSubscription = async (email: Types.ParamsSubscriptions): Promise<void> => {
    checkStateLoading();
    try {
      const { subscriptions } = await validateSubscription(email);
      if (subscriptions.length) {
        const hasActiveSubscription = subscriptions.some((subscription) => subscription.isActive);
        setIsSubscribed(hasActiveSubscription);
      } else {
        setIsSubscribed(false);
      }
    } catch (e) {
      const error = e as Error;
      showErrorToast(error.message, CONFIG_TOAST_ALERT);
    } finally {
      toggleLoading();
    }
  };

  useEffect(() => {
    fetchSubscription(email);
  }, []);

  return {
    isSubscribed,
    isLoading,
  };
};
