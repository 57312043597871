import type { FC, SyntheticEvent } from 'react';
import Button from '@material-tailwind/react/components/Button';
import { useCtxUser } from '../../../../contexts/dish/user';
import { Analytics } from '../../../../controllers';
import Hero from '../../../../components/Hero';

const MainView: FC = (): JSX.Element => {
  const { toggleDrawerAuth } = useCtxUser();

  const handleOnclickSubscribe = (e: SyntheticEvent): void => {
    e.preventDefault();
    toggleDrawerAuth();

    Analytics.sendEventCustom({
      category: 'Landing Page',
      action: 'Click',
      label: 'Suscribirse ahora',
    });
  };

  const handleOnClickMoreInfo = (e: SyntheticEvent): void => {
    e.preventDefault();

    window.location.href = '#info-gold-plus';

    Analytics.sendEventCustom({
      category: 'Landing Page',
      action: 'Click',
      label: 'Quiero saber más',
    });
  };

  return (
    <Hero
      title={
        "SkyAlert y Dish® te ofrecen <br> <span className='text-[#F6D588]'>GOLD</span>+, tu aliado en prevención"
      }
      subtitle='<span className="font-bold">Más que un paquete, la tranquilidad que te mereces.</span> <br className="hidden lg:block"> Salvaguarda lo que más valoras con el alertamiento sísmico tempano en tu celular y computadora.'
    >
      <div className='flex flex-col md:flex-row mt-10 mb-8 md:mb-6'>
        <Button
          color='white'
          className='w-full md:w-2/4 md:max-w-[270px] h-18 lg:h-14 2xl:h-16 text-lg md:text-xl 2xl:text-2xl mb-4 md:mr-2 normal-case transition-all hover:shadow-2xl hover:shadow-blue-gray-500/20'
          onClick={handleOnclickSubscribe}
        >
          Suscribirme ahora
        </Button>
        <Button
          color='white'
          variant='outlined'
          className='w-full md:w-2/4 md:max-w-[270px] h-18 lg:h-14 2xl:h-16 normal-case md:ml-2 text-lg md:text-xl 2xl:text-2xl'
          onClick={handleOnClickMoreInfo}
        >
          Quiero saber más
        </Button>
      </div>
    </Hero>
  );
};

export default MainView;
