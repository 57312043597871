import type { FC } from 'react';
import IconButton from '@material-tailwind/react/components/IconButton';
import Icon from '@mdi/react';
import { mdiFacebook, mdiYoutube, mdiInstagram } from '@mdi/js';
import { Analytics } from '../../controllers';

interface SocialMediaItem {
  name: 'Twitter' | 'Facebook' | 'Instagram' | 'Youtube';
  link: string;
  type: 'path' | 'image';
  asset: string;
  alt?: string;
}

interface SocialMediaProps {
  styleIcon?: string;
}

const SocialMedia: FC<SocialMediaProps> = ({ styleIcon }): JSX.Element => {
  const handleOnClickSocialMedia = (name: string): void => {
    Analytics.sendEventCustom({
      category: 'Social media',
      action: 'click',
      label: `Redireccionando a la red social de ${name}`,
    });
  };

  const ListSocialMedia: SocialMediaItem[] = [
    {
      name: 'Twitter',
      link: 'https://twitter.com/skyalertmx',
      asset: '/assets/logos/twitter-x.svg',
      alt: 'twitter',
      type: 'image',
    },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/SkyAlertMx/',
      type: 'path',
      asset: mdiFacebook,
    },
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/skyalertmx/',
      type: 'path',
      asset: mdiInstagram,
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/channel/UCJwJrlu4G7kmOKyoVXzUhbA',
      type: 'path',
      asset: mdiYoutube,
    },
  ];

  const classNameIcon = styleIcon || 'h-8 w-8 lg:h-6 lg:w-6';
  return (
    <>
      {ListSocialMedia.map(({ name, link, asset, alt, type }) => (
        <a
          key={name}
          href={link}
          target='_blank'
          rel='noreferrer noopener'
          onClick={() => handleOnClickSocialMedia(name)}
        >
          <IconButton
            variant='text'
            className={`rounded-full ${type === 'image' && !styleIcon ? 'h-10 w-10' : ''}`}
          >
            {type === 'path' ? (
              <Icon path={asset} size={1} className={classNameIcon} />
            ) : (
              <img src={asset} alt={alt} className={classNameIcon} />
            )}
          </IconButton>
        </a>
      ))}
    </>
  );
};

SocialMedia.defaultProps = {
  styleIcon: '',
};

export default SocialMedia;
