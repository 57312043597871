import type { FC, HTMLProps } from 'react';
import { FALLBACK_PRODUCT_GOLD_PLUS } from '../../config/constants';

interface LogoProps extends HTMLProps<HTMLImageElement> {
  isLogoCollaboration?: boolean;
  isLogoGoldPlus?: boolean;
  variant?: 'white' | 'normal';
}

const Logo: FC<LogoProps> = ({
  isLogoCollaboration = false,
  isLogoGoldPlus = false,
  variant = 'normal',
  src,
  ...props
}): JSX.Element => {
  const isVariantWhite = variant === 'white';
  if (isLogoGoldPlus) {
    return (
      <img src={src || FALLBACK_PRODUCT_GOLD_PLUS.images[0]} alt='SkyAlert Gold Plus' {...props} />
    );
  }

  const logoSrc = isLogoCollaboration
    ? `/assets/logo-skyalert-dish${isVariantWhite ? '-white' : ''}.webp`
    : src;
  const logoAlt = isLogoCollaboration ? 'SkyAlert y Dish' : 'SkyAlert';

  return <img src={logoSrc || '/assets/logo-skyalert.png'} alt={logoAlt} {...props} />;
};

Logo.defaultProps = {
  isLogoCollaboration: false,
  isLogoGoldPlus: false,
  variant: 'normal',
};

export default Logo;
