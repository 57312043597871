import { Names } from '../types';

const names: Names = {
  paths: {
    landing: '/',
    payment: '/compra',
    terms: '/terminos-y-condiciones',
    thanks: '/gracias-por-tu-compra',
    recovery: '/recuperar-contrasena',
    notFound: '/not-found',
    subscribed: '/suscrito-con-gold-plus',
    canacintra: '/canacintra',
  },
  storage: {
    user: 'sky_i.u',
    authToken: 'sky_i.at',
    cryptoKey: 'sky_i.ck',
  },
  prefixAuth: 'Bearer',
};

export default names;
