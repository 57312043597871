import { type FC } from 'react';
import Drawer from '@material-tailwind/react/components/Drawer';
import IconButton from '@material-tailwind/react/components/IconButton';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useCtxUser } from '../../../../contexts/dish/user';
import { useBreakpoints } from '../../../../hooks';
import Logo from '../../../../components/Logo';
import AuthForm from './AuthForm';

import 'react-spring-bottom-sheet/dist/style.css';

const SCREEN_WIDTH_DRAWER = 600;

const DrawerAuth: FC = (): JSX.Element => {
  const { isOpenDrawerAuth, toggleDrawerAuth } = useCtxUser();
  const { isLaptop, isTablet } = useBreakpoints();

  const isLargeScreen = isLaptop || isTablet;

  const renderContentDrawer = (): JSX.Element => (
    <>
      <div className='flex items-center justify-end p-2 lg:p-0 2xl:p-4 !z-20'>
        <IconButton variant='text' onClick={toggleDrawerAuth}>
          <Icon path={mdiClose} size={1} />
        </IconButton>
      </div>
      <Logo isLogoCollaboration className='h-12 2xl:h-16 w-auto mb-5 self-center m-auto' />
      <AuthForm isLargeScreen={isLargeScreen} />
    </>
  );

  if (isLargeScreen) {
    return (
      <Drawer
        open={isOpenDrawerAuth}
        onClose={toggleDrawerAuth}
        dismiss={{
          outsidePress: false,
          escapeKey: false,
        }}
        placement='right'
        className='flex flex-col p-4 h-screen z-50 scroll-smooth overflow-x-auto'
        overlayProps={{ className: 'fixed z-40' }}
        size={SCREEN_WIDTH_DRAWER}
      >
        {renderContentDrawer()}
      </Drawer>
    );
  }

  return (
    <BottomSheet
      open={isOpenDrawerAuth}
      className='flex flex-col p-4'
      snapPoints={({ maxHeight, height }) => [maxHeight * 0.98, height]}
      defaultSnap={({ maxHeight }) => maxHeight / 2}
    >
      {renderContentDrawer()}
    </BottomSheet>
  );
};

export default DrawerAuth;
