import type { FC, SyntheticEvent } from 'react';
import { useCtxUser } from '../../../../../contexts/dish/user';
import { Analytics } from '../../../../../controllers';
import CardDetailPackage from '../../../../../components/CardDetailPackage';
import DownloadApp from '../../../../../components/LinkDownload/App';
import DownloadHome from '../../../../../components/LinkDownload';
import Layout from '../../../../../components/Layout';
import Logo from '../../../../../components/Logo';
import GeneralInfoGoldPlus from './GeneralInfo';
import { ProductGoldPlus } from '../../../../../types';

interface MoreInfoProps {
  loading: boolean;
  detail: ProductGoldPlus | null;
}

const MoreInfoView: FC<MoreInfoProps> = ({ loading, detail }) => {
  const { toggleDrawerAuth } = useCtxUser();

  const handleClickSubscribe = (e: SyntheticEvent) => {
    e.preventDefault();
    toggleDrawerAuth();

    Analytics.sendEventCustom({
      category: 'Landing page',
      action: 'Click',
      label: 'Quiero saber más - Suscribirse ahora',
    });
  };

  const handleOnRedirectLinkDownload = (store: string) => {
    Analytics.sendEventCustom({
      category: 'Landing page',
      action: 'Click',
      label: `Quiero saber más - Descarga la app ${store}`,
    });
  };

  return (
    <Layout
      id='info-gold-plus'
      showNavbar={false}
      styleFooter='left-0'
      styleLayout='flex flex-col wrapper-info relative lg:justify-center lg:h-screen lg:w-full p-8 sm:p-12 md:px-32 lg:pt-24 lg:py-20 lg:px-16 2xl:p-20 2xl:py-10 lg:min-h-[660px] 2xl:min-h-[850px]'
    >
      <Logo
        isLogoCollaboration
        variant='normal'
        className='block h-[3.5em] 2xl:h-[4.5em] w-auto self-start mb-4 lg:mb-auto 2xl:mt-16'
      />
      <section>
        <div className='flex flex-col lg:flex-row'>
          <GeneralInfoGoldPlus
            loading={loading}
            detail={detail}
            onClickSubscribe={handleClickSubscribe}
          />
          <div className='flex flex-col lg:flex-row w-full justify-center  2xl:-mt-16'>
            <CardDetailPackage
              imageHeader='/assets/logo-app-gold.png'
              features={[
                ' <li>Recibe alertas sísmicas en tu <span className="font-gotham-bold">ubicación GPS</span> y hasta <span className="font-gotham-bold">tres localidades más</span></li>',
                ' <li>Alertas de otros <span className="font-gotham-bold">peligros naturales</span> como: tsunamis, tormentas y ciclones tropicales</li>',
                ' <li>Olvídate de los anuncios dentro de la aplicación</li>',
              ]}
              footer={<DownloadApp onRedirect={handleOnRedirectLinkDownload} />}
            />
            <img
              src='/assets/plus-icon.png'
              alt='plus icon'
              className='h-12 lg:h-16 absolute lg:z-10 self-center mb-5 sm:mb-0 md:mb-4'
            />
            <CardDetailPackage
              imageHeader='/assets/logo-home.png'
              features={[
                ' <li>Recibe alertas sísmicas con <span className="font-gotham-bold">Tiempo Estimado de Arribo</span> (TEA) e <span className="font-gotham-bold">Intensidad local</span> y de otros peligros naturales</li>',
                ' <li>Está conectado a la <span className="font-gotham-bold">REDSkyAlert</span>, la red privada de sensores sísmicos más grande de México</li>',
                ' <li>No se necesita adquirir un equipo o hardware especial</li>',
              ]}
              footer={<DownloadHome />}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MoreInfoView;
