import type { FC, ChangeEvent } from 'react';
import Typography from '@material-tailwind/react/components/Typography';
import { mdiEmailOutline } from '@mdi/js';
import CustomInput from '../../../../../components/CustomInput';
import WrapperMD from '../../../../../components/WrapperMD';

export interface RecoveryViewProps {
  isLoading?: boolean;
  emailRecovery: string;
  error: {
    emailRecovery: string;
  };
  onChangeEmailRecovery: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ViewRecovery: FC<RecoveryViewProps> = ({
  emailRecovery,
  error,
  isLoading = false,
  onChangeEmailRecovery,
}): JSX.Element => (
  <>
    <Typography
      variant='h2'
      className='text-center text-3xl 2xl:text-4xl py-4 px-0 font-gotham-bold'
    >
      Recuperar contraseña
    </Typography>
    <Typography variant='lead' className='text-center text-lg 2xl:text-xl leading-tight'>
      <WrapperMD content='Escribe la dirección de correo electrónico con el que te registraste a SkyAlert. Te enviaremos un mensaje con los pasos para reestablecer tu contraseña.' />
    </Typography>
    <div className='flex flex-col mt-6'>
      <CustomInput
        id='email-recovery'
        type='text'
        label='Correo electrónico'
        pathIcon={mdiEmailOutline}
        isDisable={isLoading}
        placeholder='ejemplo@correo.com'
        error={error.emailRecovery}
        onChange={onChangeEmailRecovery}
        value={emailRecovery}
      />
    </div>
  </>
);

ViewRecovery.defaultProps = {
  isLoading: false,
};

export default ViewRecovery;
