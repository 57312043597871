import { FC, createContext, useContext, useMemo } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import * as Constants from '../../config/constants';
import { CtxRoutesProps, StateCtxToastify } from '../../types';

const {
  OPTIONS_TOASTIFY,
  OPTIONS_TOASTIFY_INFO,
  OPTIONS_TOASTIFY_SUCCESS,
  OPTIONS_TOASTIFY_WARNING,
  OPTIONS_TOASTIFY_ERROR,
} = Constants;

const ToastifyContext = createContext<StateCtxToastify>({} as StateCtxToastify);

export const useCtxToastify = (): StateCtxToastify => {
  const context = useContext(ToastifyContext);
  if (!context) {
    throw new Error('useToastify must be used within a ToastifyProvider');
  }

  return context;
};

const ToastifyProvider: FC<CtxRoutesProps> = ({ children }: CtxRoutesProps): JSX.Element => {
  const showToast: StateCtxToastify['showToast'] = (message, options) => {
    toast(message, {
      ...OPTIONS_TOASTIFY,
      ...options,
    });
  };

  const showInfoToast: StateCtxToastify['showToast'] = (message, options) => {
    toast(message, {
      ...OPTIONS_TOASTIFY_INFO,
      ...options,
    });
  };

  const showSuccessToast: StateCtxToastify['showSuccessToast'] = (message, options) => {
    toast(message, {
      ...OPTIONS_TOASTIFY_SUCCESS,
      ...options,
    });
  };

  const showWarningToast: StateCtxToastify['showWarningToast'] = (message, options) => {
    toast(message, {
      ...OPTIONS_TOASTIFY_WARNING,
      ...options,
    });
  };

  const showErrorToast: StateCtxToastify['showErrorToast'] = (message, options) => {
    toast(message, {
      ...OPTIONS_TOASTIFY_ERROR,
      ...options,
    });
  };

  const value = useMemo(
    () => ({ showToast, showInfoToast, showSuccessToast, showWarningToast, showErrorToast }),
    [showToast, showInfoToast, showSuccessToast, showWarningToast, showErrorToast],
  );

  return (
    <ToastifyContext.Provider value={value}>
      <ToastContainer />
      {children}
    </ToastifyContext.Provider>
  );
};

export default ToastifyProvider;
