import { type FC } from 'react';

// TODO: ADD TYPE OF DELEGATION

interface NavbarCanacintraPros {
  delegation: string;
}

export const NavbarCanacintra: FC<NavbarCanacintraPros> = ({
  delegation,
}: NavbarCanacintraPros) => {
  return (
    <section className='flex justify-between mt-4 mr-6 ml-8'>
      <img
        src='/assets/logos/logo-skyalert-canacintra.webp'
        className='h-7 md:h-9 lg:h-14 '
        alt='SkyAlert | Canacintra'
      />
      <img
        src={`/assets/canacintra/delegation/${delegation}.webp`}
        className='h-7 md:h-9 lg:h-14 '
        alt={`Canacintra | ${delegation} `}
      />
    </section>
  );
};

export default NavbarCanacintra;
