import * as Types from '../types';

const Envs: Types.Envs = {
  bugsnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  skyAlertApiKey: process.env.REACT_APP_SKYALERT_API_KEY || '',
  skyAlertBaseUrl: process.env.REACT_APP_SKYALERT_BASE_URL || '',
  skyAlertAllyBaseUrl: process.env.REACT_APP_SKYALERT_ALLY_BASE_URL || '',
  skyAlertAllyApiKey: process.env.REACT_APP_SKYALERT_ALLY_API_KEY || '',
  stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISH_API_KEY || '',
  productIdStripeGoldPlus: process.env.REACT_APP_PRODUCT_ID_STRIPE_GOLD_PLUS || '',
  googleAnalyticsAllyDishApiKey: process.env.REACT_APP_GOOGLE_ANALYTICS_ALLY_DISH_API_KEY || '',
  googleAnalyticsAllyCanacintraApiKey:
    process.env.REACT_APP_GOOGLE_ANALYTICS_ALLY_CANACINTRA_API_KEY || '',
};

export default Envs;
