import { type FocusEvent, type ChangeEvent, type FC, type KeyboardEvent, useState } from 'react';
import Typography from '@material-tailwind/react/components/Typography';
import IconButton from '@material-tailwind/react/components/IconButton';
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAlertCircleOutline,
  mdiCloseCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';

// TODO: REFACTOR PROPS (EXTEND FROM INPUT HTML)
interface InputProps {
  id: string;
  value: string;
  label?: string;
  error?: string;
  pathIcon?: string;
  isDisable: boolean;
  isClear?: boolean;
  placeholder: string;
  styleInput?: string;
  styleContainer?: string;
  type: 'password' | 'text';
  onBlur?: (props: FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onChange: (props: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (props: KeyboardEvent<HTMLInputElement>) => void;
}

const CustomInput: FC<InputProps> = ({
  id,
  type,
  pathIcon,
  value,
  label,
  error,
  onChange,
  onBlur,
  onKeyDown,
  onClear,
  isClear,
  isDisable,
  styleInput,
  placeholder,
  styleContainer,
}): JSX.Element => {
  const [isShowPass, setIsShowPass] = useState<boolean>(false);
  const isPassword = type === 'password';
  const typePassword = isShowPass ? 'text' : 'password';

  const toggleShowPass = (): void => {
    setIsShowPass(!isShowPass);
  };

  const errorStyle =
    '!border-2 !border-red-600 focus:!border-red-500 focus:!ring-red-500/10 focus:!ring-red-500 placeholder-red-100 !text-red-600';
  const stylePasswordInput = isPassword ? 'pr-10' : '';
  const styleIconPaddingLeft = pathIcon ? 'pl-10' : 'pl-3';
  const styledInput = `${styleInput} ${stylePasswordInput} ${styleIconPaddingLeft} ${
    error ? errorStyle : ''
  }`;

  return (
    <div className={styleContainer}>
      <Typography className='block font-black text-[0.93rem] mb-2 leading-6 text-[#30313D]'>
        {label ?? ''}
      </Typography>
      <div className='relative z-1'>
        <Icon
          path={pathIcon ?? ''}
          size={1}
          color={error ? '#ff1b1b45' : '#00000045'}
          className='absolute top-1/2 left-2 transform -translate-y-1/2 z-[3]'
        />
        <input
          id={id}
          name={id}
          value={value}
          disabled={isDisable}
          type={type !== 'password' ? type : typePassword}
          placeholder={placeholder}
          className={`relative w-full h-[2.7rem] transition pr-9 py-2 rounded-xl shadow-sm font-gotham border border-gray-400/40 bg-white ring-transparent disabled:bg-gray-100 disabled:text-gray-500 placeholder-gray-500 focus:py-[0.45rem] focus:outline-0 focus:border-1 focus:ring-4 focus:!border-[#ff962b80] focus:ring-orange-800/20 focus:ring-orange-800 disabled:shadow-none input-form ${styledInput}`}
          onFocus={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {type === 'password' && (
          <IconButton
            variant='text'
            color='gray'
            className='rounded-full !absolute !right-1 top-1/2 transform -translate-y-1/2'
            disabled={isDisable}
            onClick={toggleShowPass}
          >
            <Icon path={isShowPass ? mdiEyeOffOutline : mdiEyeOutline} size={1} color='#00000085' />
          </IconButton>
        )}
        {isClear && (
          <IconButton
            variant='text'
            color='gray'
            className='rounded-full !absolute !right-1 top-1/2 transform -translate-y-1/2'
            disabled={isDisable}
            onClick={onClear}
          >
            <Icon path={mdiCloseCircleOutline} size={1} color='#00000085' />
          </IconButton>
        )}
      </div>
      {error && (
        <Typography variant='small' color='red' className='mt-2 flex items-center gap-1 font-thin'>
          <Icon path={mdiAlertCircleOutline} size={0.75} color='red' />
          {error}
        </Typography>
      )}
    </div>
  );
};

CustomInput.defaultProps = {
  styleInput: '',
  styleContainer: '',
  error: '',
  label: '',
  pathIcon: '',
  isClear: false,
  onBlur: () => null,
  onClear: () => null,
  onKeyDown: () => null,
};

export default CustomInput;
