import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { isModeDevelop, modeStage } from '../helpers';
import PkgJSON from '../../package.json';
import Envs from '../config/envs';

function getAppVersion(): string {
  return PkgJSON.version || '1.0.0';
}

class BugsnagLogger {
  protected static instance: BugsnagLogger = new BugsnagLogger();

  private apiSecret: string;

  private isDev: boolean;

  constructor() {
    if (BugsnagLogger.instance) {
      throw new Error("Singleton Bugsnag Logger can't be instantiated more than once.");
    }

    this.apiSecret = Envs.bugsnagApiKey;
    this.isDev = isModeDevelop();
  }

  static getInstance(): BugsnagLogger {
    return BugsnagLogger.instance;
  }

  init(): void {
    if (!this.apiSecret) {
      console.error('Could not initialize Report Logger (missing api key)');
      return;
    }

    if (this.isDev) {
      console.info('Bugsnag in mode DEV');
      return;
    }

    try {
      console.log('Initialize Bugsnag');
      Bugsnag.start({
        apiKey: this.apiSecret,
        releaseStage: modeStage(),
        enabledReleaseStages: ['production', 'staging'],
        plugins: [new BugsnagPluginReact()],
        appVersion: getAppVersion(),
      });
    } catch (e) {
      console.error('Could not initialize Report Logger', e);
    }
  }

  setUser(userId: string, email: string | undefined): void {
    if (this.isDev) {
      console.log(userId, email);
      return;
    }

    Bugsnag.setUser(userId, email);
  }

  error(message: string, context?: Record<string, unknown> | Error | unknown): void {
    if (this.isDev) {
      console.error(message, context);
      return;
    }

    const contentMessageError =
      context instanceof Error ? { message: context.message, name: context.name } : context ?? {};

    Bugsnag.notify({
      name: message,
      message: JSON.stringify(contentMessageError),
    });
  }
}

export default BugsnagLogger;
