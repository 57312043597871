import type { StripeElementsOptionsMode, StripePaymentElementOptions } from '@stripe/stripe-js';
import { FALLBACK_PRODUCT_GOLD_PLUS } from './products';

const STYLE_APPEARANCE_ELEMENTS: StripeElementsOptionsMode['appearance'] = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#f57c00',
    fontFamily: 'Gotham-Book, system-ui, sans-serif',
    fontSmooth: 'auto',
    borderRadius: '0.75rem',
  },
  rules: {
    '.Label': {
      fontWeight: '700',
      lineHeight: '1.5rem',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    '.Input::placeholder': {
      color: '#9e9e9e',
      fontWeight: '400',
      fontSize: '0.875rem',
    },
  },
};

export const STRIPE_ELEMENTS_OPTIONS: StripeElementsOptionsMode = {
  mode: 'subscription',
  setup_future_usage: 'off_session',
  amount: FALLBACK_PRODUCT_GOLD_PLUS.price.unitAmount as number,
  currency: FALLBACK_PRODUCT_GOLD_PLUS.price.currency,
  appearance: STYLE_APPEARANCE_ELEMENTS,
};

export const OPTIONS_PAYMENT_ELEMENT: StripePaymentElementOptions = {
  paymentMethodOrder: ['applePay', 'googlePay', 'paypal'],
  terms: {
    applePay: 'auto',
    googlePay: 'auto',
    paypal: 'auto',
  },
};
