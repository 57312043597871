import type { FC } from 'react';
import Button from '@material-tailwind/react/components/Button';
import Dialog, { DialogProps } from '@material-tailwind/react/components/Dialog';
import DialogHeader from '@material-tailwind/react/components/Dialog/DialogHeader';
import DialogBody from '@material-tailwind/react/components/Dialog/DialogBody';
import DialogFooter from '@material-tailwind/react/components/Dialog/DialogFooter';

interface DialogLogoutProps {
  isOpen: DialogProps['open'];
  handleStateDialog: () => void;
  onCloseDialog: () => void;
}

const DialogLogout: FC<DialogLogoutProps> = ({ isOpen, onCloseDialog, handleStateDialog }) => {
  return (
    <Dialog open={isOpen} size='sm' handler={handleStateDialog}>
      <DialogHeader>¡Atención!</DialogHeader>
      <DialogBody divider>
        Tu sesión ha caducado. Por favor, vuelve a iniciar sesión para continuar disfrutando.
      </DialogBody>
      <DialogFooter>
        <Button color='orange' onClick={onCloseDialog}>
          <span>Aceptar</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DialogLogout;
