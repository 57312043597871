import type { FC } from 'react';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';

interface LinkDownloadProps {
  onRedirect?: (store: string) => void;
}

interface ButtonDownload {
  image: string;
  alt: string;
  link: string;
  style: string;
  store: string;
}

const BUTTONS_DOWNLOAD_APP: ButtonDownload[] = [
  {
    store: 'Google play',
    image: '/assets/google-download.png',
    alt: 'Descarga Google Play',
    link: 'https://play.google.com/store/apps/details?id=com.disappster.skyalert&hl=en&gl=US',
    style: 'mb-4',
  },
  {
    store: 'App store',
    image: '/assets/apple-download.png',
    alt: 'Descarga App Store',
    link: 'https://apps.apple.com/mx/app/skyalert/id774381416',
    style: '',
  },
  {
    store: 'AppGallery',
    image: '/assets/huawei-download.png',
    alt: 'Descarga AppGallery',
    link: 'https://appgallery.huawei.com/app/C111510189',
    style: 'mt-4',
  },
];

const DownloadApp: FC<LinkDownloadProps> = ({ onRedirect }): JSX.Element => (
  <div className='flex flex-col'>
    {BUTTONS_DOWNLOAD_APP.map(({ store, image, alt, link, style }, index) => (
      <Typography
        as='a'
        href={link}
        target='_blank'
        rel='noreferrer noopener'
        key={`button-download-${index + 1}`}
        className={`w-32 2xl:w-36 ${style}`}
        onClick={() => onRedirect && onRedirect(store)}
      >
        <Button className='button-app bg-transparent w-full h-auto p-0'>
          <img src={image} alt={alt} className='h-full w-full' />
        </Button>
      </Typography>
    ))}
  </div>
);

DownloadApp.defaultProps = {
  onRedirect: () => null,
};

export default DownloadApp;
