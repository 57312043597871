/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, type FC, type ChangeEvent, useEffect } from 'react';
import {
  mdiEmailOutline,
  mdiAccountCircleOutline,
  mdiDomain,
  mdiPhoneClassic,
  mdiSegment,
  mdiLoading,
  mdiCity,
} from '@mdi/js';
import Button from '@material-tailwind/react/components/Button';
import Checkbox from '@material-tailwind/react/components/Checkbox';
import Typography from '@material-tailwind/react/components/Typography';
import Icon from '@mdi/react';
import InputCanacintra from '../../../components/InputCanacintra';
import {
  validateIsEmailPattern,
  validateIsFullNamePattern,
  validateIsCompanyNamePattern,
  validateIsPhonePattern,
} from '../../../utils';
import * as Constants from '../../../config/constants';
import * as Types from '../../../types';

interface FormCanacintraProps {
  onSubmit: (dataForm: Types.FormDataAllySubscriptions) => Promise<void>;
  isLoading: boolean;
  userInfo: Types.ParamsDataCanacintra;
}

const { DEFAULT_FIELDS, OPTIONS_ENTERPRISE, OPTIONS_DELEGATION } = Constants;

export const Form: FC<FormCanacintraProps> = ({
  onSubmit,
  isLoading,
  userInfo,
}: FormCanacintraProps) => {
  const [fields, setFields] = useState<Types.FieldsForm>(DEFAULT_FIELDS);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const handleOnChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>): void => {
    let errorText = '';

    if (name === 'userName' && value.length && !validateIsFullNamePattern(value)) {
      errorText = 'El nombre no es válido';
    }

    if (name === 'email' && value.length && !validateIsEmailPattern(value)) {
      errorText = 'El correo electrónico no es válido';
    }

    if (name === 'company' && value.length && !validateIsCompanyNamePattern(value)) {
      errorText = 'El nombre de empresa no es válido';
    }

    if (name === 'phone' && value.length && !validateIsPhonePattern(value)) {
      errorText = 'El teléfono no es válido';
    }

    setFields((prevState) => ({ ...prevState, [name]: { value, error: errorText } }));
  };

  const handleOnChangeSelect = (optionValue: string, inputId: string): void => {
    setFields((prevState) => ({ ...prevState, [inputId]: { value: optionValue, error: '' } }));
  };

  const handleOnClickCheckbox = ({ target: { checked } }: ChangeEvent<HTMLInputElement>): void => {
    setIsAgree(checked);
  };

  const handleOnClickSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const dataForm: Types.FormDataAllySubscriptions = {
      name: fields.userName.value,
      email: fields.email.value,
      company: fields.company.value,
      sector: fields.sector.value,
      phone: fields.phone.value,
      delegation: fields.delegation.value,
    };
    onSubmit(dataForm);
  };

  useEffect(() => {
    setFields((prevState) => ({
      ...prevState,
      email: { value: userInfo.email, error: '' },
      userName: { value: userInfo.name, error: '' },
      delegation: { value: userInfo.delegation, error: '' },
    }));
  }, [userInfo]);

  const isActiveSubmit: boolean =
    !!fields.userName.value &&
    !!fields.email.value &&
    !!fields.company.value &&
    !!fields.sector.value &&
    !!fields.delegation.value &&
    !!fields.phone.value &&
    !fields.userName.error &&
    !fields.email.error &&
    !fields.company.error &&
    !fields.sector.error &&
    !fields.delegation.error &&
    !fields.phone.error &&
    isAgree &&
    !isLoading;

  return (
    <div className='flex justify-center lg:my-4 2xl:mb-10'>
      <div className='md:w-9/12 lg:w-3/6 2xl:w-4/12 p-8 lg:p-12'>
        <form>
          <Typography className='font-helvetica text-3xl font-[900] mb-2'>
            ¡Bienvenido a SkyAlert!
          </Typography>
          <Typography className='mb-1 font-helvetica text-[#00000099]'>
            Para terminar con el proceso de redimir tus beneficios de la alianza de
            <span className='font-extrabold font-helvetica'> SkyAlert</span> y
            <span className='font-extrabold font-helvetica'> CANACINTRA</span>, sigue los siguientes
            pasos:
          </Typography>
          <Typography className='mb-1 text-[#00000099] font-extrabold text-lg font-helvetica'>
            Paso 1
          </Typography>
          <Typography className='text-xl font-[900] mb-4 font-helvetica'>
            Completa el siguiente formulario con tus datos:
          </Typography>
          <InputCanacintra
            id='userName'
            label='Nombre y apellidos'
            value={fields.userName.value}
            error={fields.userName.error}
            pathIcon={mdiAccountCircleOutline}
            isDisable={isLoading}
            isCorrect={!fields.userName.error && !!fields.userName.value}
            onChange={handleOnChange}
          />
          <div className='lg:flex justify-between'>
            <InputCanacintra
              id='email'
              label='Correo electrónico'
              value={fields.email.value}
              error={fields.email.error}
              pathIcon={mdiEmailOutline}
              isDisable={isLoading}
              isCorrect={!fields.email.error && !!fields.email.value}
              onChange={handleOnChange}
              styleContainer='lg:mr-2 lg:w-[55%]'
            />
            <InputCanacintra
              id='phone'
              label='Teléfono'
              value={fields.phone.value}
              error={fields.phone.error}
              pathIcon={mdiPhoneClassic}
              isDisable={isLoading}
              styleContainer='lg:ml-2 lg:w-[45%]'
              isCorrect={!fields.phone.error && !!fields.phone.value}
              onChange={handleOnChange}
            />
          </div>
          <InputCanacintra
            id='company'
            label='Empresa'
            value={fields.company.value}
            error={fields.company.error}
            pathIcon={mdiDomain}
            isDisable={isLoading}
            isCorrect={!fields.company.error && !!fields.company.value}
            onChange={handleOnChange}
          />
          <div className='lg:flex justify-between'>
            <InputCanacintra
              id='sector'
              label='Sector empresarial'
              value={fields.sector.value}
              error={fields.sector.error}
              type='select'
              options={OPTIONS_ENTERPRISE}
              pathIcon={mdiSegment}
              isDisable={isLoading}
              styleContainer='lg:mr-2  lg:w-[55%]'
              isCorrect={!fields.sector.error && !!fields.sector.value}
              onChangeSelect={handleOnChangeSelect}
            />
            <InputCanacintra
              id='delegation'
              label='Delegación'
              value={fields.delegation.value}
              error={fields.delegation.error}
              type='select'
              options={OPTIONS_DELEGATION}
              pathIcon={mdiCity}
              isDisable={isLoading}
              styleContainer='lg:ml-2 lg:w-[45%]'
              isCorrect={!fields.delegation.error && !!fields.delegation.value}
              onChangeSelect={handleOnChangeSelect}
            />
          </div>
          <Checkbox
            label={
              <Typography className='flex flex-wrap text-sm'>
                He leído y estoy de acuerdo con el&nbsp;
                <Typography
                  as='a'
                  className='text-sm underline font-helvetica'
                  target='_blank'
                  rel='noreferrer noopener'
                  href='https://skyalert.mx/aviso-de-privacidad'
                >
                  Aviso de Privacidad
                </Typography>
                .
              </Typography>
            }
            className=' checked:bg-[#F77711]  checked:border-[#F77711]  checked:before:bg-[#F77711] '
            onChange={handleOnClickCheckbox}
            crossOrigin={undefined}
            disabled={isLoading}
          />
          <Button
            type='submit'
            className='w-full flex justify-center mt-5 rounded-xl bg-[#F77711] px-5 py-3 normal-case text-base lg:text-xs lg:text-[1em] font-semibold text-white shadow-sm hover:bg-orange-500 font-helvetica'
            disabled={!isActiveSubmit}
            onClick={handleOnClickSubmit}
          >
            Enviar
            {isLoading && <Icon path={mdiLoading} size={0.8} className='ml-4 animate-spin' />}
          </Button>
        </form>
      </div>
      <img
        src='/assets/canacintra/form-desk.webp'
        className='hidden lg:block max-w-3/6 h-[80vh] mb-4 mt-4'
        alt='SkyAlert | Canacintra'
      />
    </div>
  );
};

export default Form;
