import ReactGA from 'react-ga4';
import BugsnagLogger from './BugsnagLogger';
import { isModeDevelop, isModeStaging } from '../helpers';
import Envs from '../config/envs';
import * as Types from '../types';

interface ParamsEventCustom {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

type AccountAnalytics = 'Dish' | 'Canacintra';

type KeyEnv = { [key in AccountAnalytics]: keyof Types.Envs };

const LoggerInstance = BugsnagLogger.getInstance();

const KEY_ACCOUNT_ANALYTICS: KeyEnv = {
  Dish: 'googleAnalyticsAllyDishApiKey',
  Canacintra: 'googleAnalyticsAllyCanacintraApiKey',
};

class Analytics {
  protected static instance: Analytics = new Analytics();

  private apiKey: string;

  private stage: 'staging' | 'production';

  private isDev: boolean;

  constructor() {
    if (Analytics.instance) {
      throw new Error("Singleton Analytics can't be instantiated more than once.");
    }

    this.apiKey = '';
    this.isDev = isModeDevelop();
    this.stage = isModeStaging() ? 'staging' : 'production';
  }

  static getInstance(): Analytics {
    return Analytics.instance;
  }

  init(account: AccountAnalytics): void {
    this.apiKey = Envs[KEY_ACCOUNT_ANALYTICS[account]];

    if (this.isDev || this.stage !== 'production') {
      console.log('Analytics in mode DEV');
      return;
    }

    if (!this.apiKey) {
      LoggerInstance.error('Could not initialize Analytics (missing api key)');
      return;
    }

    console.log('Initialize Analytics');
    ReactGA.initialize(this.apiKey, {
      testMode: this.isDev,
    });
  }

  static sendPageView(page: string, title: string): void {
    ReactGA.send({ hitType: 'pageview', page, title });
  }

  static sendEventCustom(params: ParamsEventCustom): void {
    ReactGA.event(params);
  }
}

export default Analytics;
