import { FC } from 'react';
import Lotties from '../Lotties';

const Fallback: FC = (): JSX.Element => {
  return (
    <div className='bg-white min-h-screen flex items-center'>
      <Lotties animation='Loading' />
    </div>
  );
};

export default Fallback;
