import type { CreateAxiosDefaults } from 'axios';
import Envs from './envs';

const { skyAlertBaseUrl, skyAlertApiKey, skyAlertAllyBaseUrl, skyAlertAllyApiKey } = Envs;

const configAxios: CreateAxiosDefaults = {
  baseURL: skyAlertBaseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': skyAlertApiKey,
  },
};

const configAxiosAlly: CreateAxiosDefaults = {
  baseURL: skyAlertAllyBaseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': skyAlertAllyApiKey,
  },
};

export { configAxios, configAxiosAlly };
