import { createContext, useReducer, useContext, useMemo, memo, useEffect } from 'react';
import { subscriptionReducer } from '../../reducers';
import { getProducts, getPrices } from '../../services';
import { BugsnagLogger } from '../../controllers';
import { useCtxToastify } from '../common/toastify';
import { FALLBACK_PRODUCT_GOLD_PLUS } from '../../config/constants';
import Envs from '../../config/envs';
import * as Types from '../../types';

const Logger = BugsnagLogger.getInstance();

const PRODUCT_STRIPE_GOLD_PLUS = Envs.productIdStripeGoldPlus;

const SubscriptionContext = createContext<Types.CtxSubscription>({} as Types.CtxSubscription);

export const useCtxSubscription = (): Types.CtxSubscription => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useCtxSubscription must be used within a SubscriptionContext');
  }

  return context;
};

export const SubscriptionProvider = ({ children }: Types.CtxRoutesProps): JSX.Element => {
  const { showErrorToast } = useCtxToastify();

  const initialState: Types.StateCtxSubscription = {
    isLoadingRequest: false,
    isShowPromotionCode: false,
    goldPlus: null,
    detailPurchase: {
      subtotal: 0,
      discount: 0,
      total: 0,
    },
    promotionCode: {
      discountAmount: null,
      code: '',
      percent: null,
      id: '',
    },
  };

  const [state, dispatch] = useReducer(subscriptionReducer, initialState);

  const fetchProductGoldPlus = async (): Promise<Types.ProductSkyAlert> => {
    if (!PRODUCT_STRIPE_GOLD_PLUS) {
      throw new Error(`Product with ID ${PRODUCT_STRIPE_GOLD_PLUS} not found.`);
    }

    const products = await getProducts();
    const productGoldPlus = products.find((product) => product.id === PRODUCT_STRIPE_GOLD_PLUS);

    if (!productGoldPlus) {
      return FALLBACK_PRODUCT_GOLD_PLUS as Types.ProductSkyAlert;
    }

    return productGoldPlus;
  };

  const fetchPriceGoldPlus = async (
    id: Types.ProductSkyAlert['default_price'],
  ): Promise<Types.PriceGoldPlus> => {
    if (!id) {
      return FALLBACK_PRODUCT_GOLD_PLUS.price as Types.PriceGoldPlus;
    }

    const goldPlusPrice = (await getPrices()).find((price) => price.id === id);

    if (!goldPlusPrice) {
      throw new Error(`Price with ID ${id} not found.`);
    }

    const price: Types.PriceGoldPlus = {
      id: goldPlusPrice.id,
      currency: goldPlusPrice.currency,
      unitAmount: goldPlusPrice.unit_amount,
      unitAmountDecimal: goldPlusPrice.unit_amount_decimal,
      recurring: goldPlusPrice.recurring,
      type: goldPlusPrice.type,
    };

    return price;
  };

  const toggleLoading = (isLoadingRequest = false): void => {
    dispatch({
      type: Types.EActionsSubscription.SetLoadingRequest,
      isLoadingRequest,
    });
  };

  const toggleShowPromotionCode = (): void => {
    const { isShowPromotionCode } = state;
    dispatch({
      type: Types.EActionsSubscription.SetIsShowPromotionCode,
      isShowPromotionCode: !isShowPromotionCode,
    });
  };

  const fetchProductGoldPlusSkyAlert = async (): Promise<void> => {
    const { isLoadingRequest } = state;
    try {
      if (!isLoadingRequest) {
        toggleLoading(true);
      }

      const goldPlus = await fetchProductGoldPlus();
      const goldPlusPrice = await fetchPriceGoldPlus(goldPlus.default_price);

      dispatch({
        type: Types.EActionsSubscription.SetInformationGoldPlus,
        goldPlus: {
          id: goldPlus.id,
          description: goldPlus.description,
          name: goldPlus.name,
          liveMode: goldPlus.livemode,
          images: goldPlus.images,
          type: goldPlus.type,
          metadata: goldPlus.metadata,
          price: goldPlusPrice,
        },
      });

      dispatch({
        type: Types.EActionsSubscription.SetDetailPurchase,
        detailPurchase: {
          subtotal: goldPlusPrice.unitAmount as number,
          discount: 0,
          total: goldPlusPrice.unitAmount as number,
        },
      });
    } catch (error) {
      Logger.error('Could not fetching info for product gold plus - "useProductGoldPlus"', error);
      showErrorToast('Hubo un problema al obtener la información del paquete');
    } finally {
      toggleLoading();
    }
  };

  useEffect(() => {
    fetchProductGoldPlusSkyAlert();
  }, []);

  const updateStatePromotionCode: Types.CtxSubscription['updateStatePromotionCode'] = (
    promotionCode,
  ) => {
    dispatch({
      type: Types.EActionsSubscription.SetPromotionCode,
      promotionCode,
    });
  };

  const updateStateDetailPurchase: Types.CtxSubscription['updateStateDetailPurchase'] = (
    detailPurchase,
  ) => {
    dispatch({
      type: Types.EActionsSubscription.SetDetailPurchase,
      detailPurchase,
    });
  };

  const contextValue = useMemo(
    () => ({
      ...state,
      updateStatePromotionCode,
      updateStateDetailPurchase,
      toggleShowPromotionCode,
    }),
    [state, updateStatePromotionCode, updateStateDetailPurchase, toggleShowPromotionCode],
  );

  return (
    <SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>
  );
};

export default memo(SubscriptionProvider);
