import type { FC, ChangeEvent } from 'react';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';
import { Analytics } from '../../../../controllers';
import { useAuthForm } from '../../../../hooks';
import ViewLogin from './views/Login';
import ViewRecovery from './views/Recovery';
import ViewSignUp from './views/SignUp';
import * as Types from '../../../../types';

interface FormAuthProps {
  isLargeScreen?: boolean;
}

type KeyTextButtonStateView = { [key in Types.ViewAuth]: string };

type KeyTextActionViewState = {
  [key in Types.ViewAuth]: {
    text: string;
    action: string;
  };
};

type NextViewAuth = { [key in Types.ViewAuth]: Types.ViewAuth };

type ChangeEventInput = ChangeEvent<HTMLInputElement>;

const NEXT_VIEW_AUTH: NextViewAuth = {
  login: Types.EViewsAuth.SignUp,
  signUp: Types.EViewsAuth.Login,
  recovery: Types.EViewsAuth.Login,
};

const TEXT_VIEW_BUTTON_PRIMARY_ACTION: KeyTextButtonStateView = {
  login: 'Iniciar sesión',
  signUp: 'Comenzar',
  recovery: 'Enviar',
};

const TEXT_VIEW_BUTTON_SECONDARY_ACTION: KeyTextButtonStateView = {
  login:
    '¿Olvidaste tu contraseña? No te preocupes. Con nuestra aplicación, puedes recuperar tu contraseña en pocos pasos.',
  signUp: '',
  recovery: 'Volver a iniciar sesión',
};

const TEXT_VIEW_BUTTON_TERTIARY_ACTION: KeyTextActionViewState = {
  login: {
    text: '¿No estás registrado? ',
    action: 'Crear una cuenta',
  },
  recovery: {
    text: '',
    action: '',
  },
  signUp: {
    text: '¿Ya tienes una cuenta? ',
    action: 'Inicia sesión',
  },
};

const FormAuth: FC<FormAuthProps> = ({ isLargeScreen }): JSX.Element => {
  const {
    isLoading,
    viewForm,
    setViewForm,
    fields,
    updateField,
    resetFields,
    signUpWithEmail,
    loginWithEmail,
    recoveryPassword,
    validateLoginFields,
    validateRecoveryFields,
    validateSignUpFields,
  } = useAuthForm({
    isLargeScreen,
  });

  const isViewSignUp = viewForm === Types.EViewsAuth.SignUp;
  const isViewRecovery = viewForm === Types.EViewsAuth.Recovery;

  const handleOnChangeEmail = ({ target: { value } }: ChangeEventInput): void => {
    updateField(viewForm, Types.EFieldsAuth.Email, value);
  };

  const handleOnChangeEmailConfirm = ({ target: { value } }: ChangeEventInput): void => {
    updateField(viewForm, Types.EFieldsAuth.EmailConfirm, value);
  };

  const handleOnChangePassword = ({ target: { value } }: ChangeEventInput): void => {
    updateField(viewForm, Types.EFieldsAuth.Password, value);
  };

  const authLoginEmail = (): void => {
    const isValidLoginFields = validateLoginFields();
    if (isValidLoginFields) {
      const { email, password } = fields.login;
      loginWithEmail(email.value, password.value);
    }
  };

  const authRecoveryForLogin = (): void => {
    const isValidRecoveryFields = validateRecoveryFields();
    if (isValidRecoveryFields) {
      const { email } = fields.recovery;
      recoveryPassword(email.value);

      // TODO: REMOVE THIS IF NOT IS NECESSARY
      setTimeout(() => {
        setViewForm(Types.EViewsAuth.Login);
      }, 250);
    }
  };

  const authSignUpUser = (): void => {
    const isValidSignUpFields = validateSignUpFields();
    if (isValidSignUpFields) {
      const { email, password } = fields.signUp;
      signUpWithEmail(email.value, password.value);
    }
  };

  const handleOnClickSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    switch (viewForm) {
      case Types.EViewsAuth.Login:
        authLoginEmail();
        break;
      case Types.EViewsAuth.Recovery:
        authRecoveryForLogin();
        break;
      default:
        authSignUpUser();
        break;
    }
  };

  // TODO: UNCOMMENT WHEN THE PASSWORD RECOVER PART IS HERE
  // const handleOnChangeSignUp = (): void => {
  //   setViewForm(isViewRecovery ? Types.EViewsAuth.Login : Types.EViewsAuth.Recovery);
  // };

  const handleOnChangeViewForm = (): void => {
    Analytics.sendEventCustom({
      category: 'Landing page - Autenticación',
      action: 'Click',
      label: 'Cambiar de vista de formulario',
    });
    resetFields();
    setViewForm(NEXT_VIEW_AUTH[viewForm]);
  };

  const renderViewForm = (): JSX.Element => {
    const { login, recovery, signUp } = fields;
    switch (viewForm) {
      case Types.EViewsAuth.Login:
        return (
          <ViewLogin
            error={{
              email: login.email.error,
              password: login.password.error,
            }}
            onChangeEmail={handleOnChangeEmail}
            onChangePassword={handleOnChangePassword}
            email={login.email.value}
            password={login.password.value}
            isLoading={isLoading}
          />
        );
      case Types.EViewsAuth.Recovery:
        return (
          <ViewRecovery
            error={{
              emailRecovery: recovery.email.error,
            }}
            onChangeEmailRecovery={handleOnChangeEmail}
            emailRecovery={recovery.email.value}
            isLoading={isLoading}
          />
        );
      default:
        return (
          <ViewSignUp
            error={{
              email: signUp.email.error,
              emailConfirm: signUp.emailConfirm.error,
              password: signUp.password.error,
            }}
            onChangeEmail={handleOnChangeEmail}
            onChangeEmailConfirm={handleOnChangeEmailConfirm}
            onChangePassword={handleOnChangePassword}
            email={signUp.email.value}
            emailConfirm={signUp.emailConfirm.value}
            password={signUp.password.value}
            isLoading={isLoading}
          />
        );
    }
  };

  // TODO: CHANGE TYPOGRAPHY FOR BUTTON WHEN THE PASSWORD RECOVER PART IS HERE
  return (
    <form className='flex-1 flex flex-col w-10/12 sm:w-3/4 md:w-10/12 m-auto'>
      {renderViewForm()}
      <Button
        type='submit'
        className='flex justify-center mt-5 rounded-2xl bg-orange-600 px-3.5 py-2.5 normal-case text-base lg:text-xs lg:text-[1em] font-semibold text-white shadow-sm hover:bg-orange-500'
        disabled={isLoading}
        onClick={handleOnClickSubmit}
      >
        {TEXT_VIEW_BUTTON_PRIMARY_ACTION[viewForm]}
        {isLoading && <Icon path={mdiLoading} size={0.8} className='ml-4 animate-spin' />}
      </Button>
      {!isViewSignUp && (
        <Typography
          variant='small'
          className='mt-6 rounded-xl p-0 text-center normal-case font-normal text-sm hover:bg-transparent active:bg-transparent animate-none'
        >
          {TEXT_VIEW_BUTTON_SECONDARY_ACTION[viewForm]}
        </Typography>
      )}
      {!isViewRecovery && (
        <Typography
          className={`text-center font-normal text-base lg:text-xs lg:text-[1em] ${
            !isViewRecovery ? 'mt-6' : ''
          }`}
        >
          {TEXT_VIEW_BUTTON_TERTIARY_ACTION[viewForm].text}
          <span> </span>
          <Button
            variant='text'
            className='normal-case rounded-xl font-normal text-base lg:text-xs lg:text-[1em] text-blue-600 hover:text-blue-700 hover:bg-transparent active:bg-transparent animate-none p-0 '
            onClick={handleOnChangeViewForm}
            disabled={isLoading}
          >
            {TEXT_VIEW_BUTTON_TERTIARY_ACTION[viewForm].action}
          </Button>
        </Typography>
      )}
    </form>
  );
};

FormAuth.defaultProps = {
  isLargeScreen: false,
};

export default FormAuth;
