import type { FC } from 'react';
import CardHeader from '@material-tailwind/react/components/Card/CardHeader';
import CardFooter from '@material-tailwind/react/components/Card/CardFooter';
import Typography from '@material-tailwind/react/components/Typography';
import WrapperMD from '../WrapperMD';

interface CardDetailPackageProps {
  imageHeader: string;
  features: string[];
  footer: JSX.Element;
}

const CardDetailPackage: FC<CardDetailPackageProps> = ({
  imageHeader,
  features,
  footer,
}): JSX.Element => {
  return (
    <div className='cardDetail 2xl:relative flex flex-col items-center 2xl:min-h-[650px] p-8 lg:w-[50%] lg:max-h-[600px] lg:min-w-[310px] xl:w-[45%] mb-5 lg:mb-0 lg:mx-3 2xl:my-5 rounded-2xl border-2 border-[#F6D588] bg-[#FFFAF466]'>
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className='m-0 mb-4 rounded-none border-b border-white/10 pb-2 '
      >
        <img src={imageHeader} alt='logo paquete' className='h-16 2xl:h-20 2xl:mb-4' />
      </CardHeader>
      <div>
        <ol className='list-disc list-outside ml-5'>
          {features.map((element, index) => (
            <Typography
              className='self-start text-lg md:text-xl lg:text-base 2xl:text-2xl leading-4 mb-5'
              key={`feature-elem-${index + 1}`}
            >
              <WrapperMD content={element} />
            </Typography>
          ))}
        </ol>
      </div>
      <CardFooter className='footer-card 2xl:absolute bottom-8 mt-4 lg:mt-6 2xl:mt-12 p-0'>
        {footer}
      </CardFooter>
    </div>
  );
};

export default CardDetailPackage;
