import { useReducer, createContext, useContext, useMemo, memo } from 'react';
import { userReducer } from '../../reducers';
import { LocalStorage } from '../../controllers';
import { setAuthorizationStorage } from '../../helpers';
import * as Types from '../../types';

const UserContext = createContext<Types.CtxUser>({} as Types.CtxUser);

const DEFAULT_USER: Types.UserInformation = {
  id: '',
  customerId: '',
  email: '',
  createdAt: '',
};

export const useCtxUser = (): Types.CtxUser => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useCtxUser must be used within a UserContext');
  }

  return context;
};

const clearStorage = (): void => {
  LocalStorage.clear();
};

export const UserProvider = ({ children }: Types.CtxRoutesProps): JSX.Element => {
  const StorageUser = new LocalStorage('user');
  const userStore = StorageUser.getItem<Types.UserInformation>();
  if (!userStore?.id || !userStore?.customerId) {
    clearStorage();
  }

  const initialState: Types.StateCtxUser = {
    isOpenDrawerAuth: false,
    information: userStore || DEFAULT_USER,
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const toggleDrawerAuth = (): void => {
    const isOpenDrawerAuth = !state.isOpenDrawerAuth;
    dispatch({
      type: Types.EActionsUser.SetDrawerState,
      isOpenDrawerAuth,
    });
  };

  const updateStateUserInformation = (userInformation: Types.UserInformation): void => {
    dispatch({
      type: Types.EActionsUser.SetUserInformationState,
      information: userInformation,
    });

    StorageUser.setItem(userInformation);
  };

  const saveResponseUser = (
    user: Types.UserInformation,
    authorization: Types.AuthenticationAccess,
  ): void => {
    updateStateUserInformation(user);
    setAuthorizationStorage(authorization);
  };

  const clearInformation = async (): Promise<void> => {
    dispatch({
      type: Types.EActionsUser.SetUserInformationState,
      information: DEFAULT_USER,
    });

    clearStorage();
  };

  const value = useMemo(
    () => ({
      ...state,
      toggleDrawerAuth,
      saveResponseUser,
      updateStateUserInformation,
      clearInformation,
    }),
    [state, toggleDrawerAuth, saveResponseUser, updateStateUserInformation, clearInformation],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default memo(UserProvider);
