import { useLandingCanacintra } from '../../hooks';
import DialogCanacintra from '../../components/DialogCanacintra';
import LayoutCanacintra from '../../components/LayoutCanacintra';
import Form from './views/Form';
import Congratulations from './views/Congratulations';
import names from '../../config/names';
import * as Types from '../../types';

const ANALYTICS_PAGE: Types.AnalyticsViewPageCanacintra = {
  page: names.paths.canacintra,
  titles: {
    main: 'Landing Page CANACINTRA - form',
    qr: 'Landing Page CANACINTRA - form with QR',
  },
};

const Canacintra = (): JSX.Element => {
  const {
    hasAccountRegister,
    isOpenDialog,
    userInfo,
    view,
    isLoadingRequest,
    handleOnSubmitForm,
    handleOnCloseDialog,
  } = useLandingCanacintra({
    analytics: ANALYTICS_PAGE,
  });

  const renderView = (): JSX.Element => {
    if (view === 'congratulations') {
      return <Congratulations />;
    }

    return <Form onSubmit={handleOnSubmitForm} isLoading={isLoadingRequest} userInfo={userInfo} />;
  };

  return (
    <LayoutCanacintra>
      {renderView()}
      <DialogCanacintra
        isExistingEmail={hasAccountRegister}
        isOpen={isOpenDialog}
        onCloseDialog={handleOnCloseDialog}
        handleStateDialog={handleOnCloseDialog}
      />
    </LayoutCanacintra>
  );
};

export default Canacintra;
