import { useEffect, useRef, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import { StripeClient } from '../controllers';

export const useStripeInstance = () => {
  const refStripe = useRef<Stripe | null>(null);

  const [isLoadingStripe, setIsLoadingStripe] = useState<boolean>(true);

  const initializeStripe = async () => {
    const stripeInstance = await StripeClient.getInstance().init();
    if (stripeInstance) {
      refStripe.current = stripeInstance;
    }
    setIsLoadingStripe(false);
  };

  useEffect(() => {
    initializeStripe();
  }, []);

  return {
    isLoadingStripe,
    instance: refStripe.current,
  };
};
