import { LocalStorage } from '../controllers';
import * as Types from '../types';

export const getAuthorizationStorage = (): Types.StorageAuthToken => {
  const Storage = new LocalStorage('authToken');
  const authToken = Storage.getItem<Types.StorageAuthToken>();
  if (!authToken)
    return {
      accessToken: '',
      refreshToken: '',
    };

  return authToken;
};

export const setAuthorizationStorage = ({
  accessToken,
  refreshToken,
}: Types.StorageAuthToken): void => {
  const Storage = new LocalStorage('authToken');
  Storage.setItem({
    accessToken,
    refreshToken,
  });
};
