import NotFound from '../lotties/not-found.json';
import NotProduct from '../lotties/not-product.json';
import Loading from '../lotties/loading.json';
import WarningSing from '../lotties/warning-sing.json';

const lotties = {
  NotFound,
  NotProduct,
  Loading,
  WarningSing,
};

export default lotties;
