import * as Types from '../types';

export const userReducer = (
  state: Types.StateCtxUser,
  action: Types.TCtxUserActions,
): Types.StateCtxUser => {
  switch (action.type) {
    case Types.EActionsUser.SetDrawerState: {
      const { isOpenDrawerAuth } = action;
      return {
        ...state,
        isOpenDrawerAuth,
      };
    }
    case Types.EActionsUser.SetUserInformationState: {
      const { information } = action;
      return {
        ...state,
        information,
      };
    }
    default: {
      const { type } = action;
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
