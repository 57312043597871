import type { FC, ReactNode } from 'react';
import CardSocialMedia from '../../CardSocialMedia';
import Layout from '..';

import './Background.css';

interface BackgroundCommonProps {
  children?: ReactNode;
}

interface BackgroundFooterProps extends BackgroundCommonProps {
  showCardSocialMedia?: boolean;
}

type BackgroundProps = FC<BackgroundCommonProps> & {
  Header: FC<BackgroundCommonProps>;
  Body: FC<BackgroundCommonProps>;
  Footer: FC<BackgroundFooterProps>;
};

const Background: BackgroundProps = ({ children }: BackgroundCommonProps): JSX.Element => (
  <Layout showNavbar styleLayout='wrapper-background md:h-screen'>
    <div className='flex flex-col items-center justify-center p-6 pt-0'>{children}</div>
  </Layout>
);

const BackgroundHeader: FC<BackgroundCommonProps> = ({ children }): JSX.Element => {
  return <div className='mt-4 mb-8'>{children}</div>;
};

const BackgroundBody: FC<BackgroundCommonProps> = ({ children }): JSX.Element => {
  return <div className='flex justify-center'>{children}</div>;
};

const BackgroundFooter: FC<BackgroundFooterProps> = ({
  children,
  showCardSocialMedia,
}: BackgroundFooterProps): JSX.Element => {
  return (
    <>
      {children}
      {showCardSocialMedia ? <CardSocialMedia /> : null}
    </>
  );
};

Background.Header = BackgroundHeader;
Background.Body = BackgroundBody;
Background.Footer = BackgroundFooter;

Background.defaultProps = {
  children: <div />,
};

BackgroundHeader.defaultProps = {
  children: <div />,
};

BackgroundBody.defaultProps = {
  children: <div />,
};

BackgroundFooter.defaultProps = {
  children: <div />,
  showCardSocialMedia: false,
};

export default Background;
