import { useState, useEffect } from 'react';

interface UseBreakpoints {
  windowWidth: number;
  windowHeight: number;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
}

interface RangeBreakpoints {
  min: number;
  max: number;
}

type ScreenBreakpoints = {
  [key in 'mobile' | 'tablet' | 'laptop']: RangeBreakpoints;
};

const SCREENS_BREAKPOINTS: ScreenBreakpoints = {
  mobile: {
    min: 400,
    max: 767,
  },
  tablet: {
    min: 768,
    max: 1023,
  },
  laptop: {
    min: 1024,
    max: 2500,
  },
};

export const useBreakpoints = (): UseBreakpoints => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const breakpoints: UseBreakpoints = {
    windowWidth,
    windowHeight,
    isMobile: windowWidth < SCREENS_BREAKPOINTS.mobile.max,
    isTablet:
      windowWidth >= SCREENS_BREAKPOINTS.tablet.min && windowWidth < SCREENS_BREAKPOINTS.tablet.max,
    isLaptop: windowWidth >= SCREENS_BREAKPOINTS.laptop.min,
  };

  return breakpoints;
};
