import { useCallback, useState } from 'react';
import type { ToastOptions } from 'react-toastify';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { useCtxUser } from '../contexts/dish/user';
import { useCtxSubscription } from '../contexts/dish/subscription';
import { useCtxToastify } from '../contexts/common/toastify';
import { createSubscriptionClient } from '../services';
import { Analytics, BugsnagLogger } from '../controllers';
import { useBreakpoints } from './useBreakPoints';
import { validateIsEmailPattern, validateIsFullNamePattern } from '../utils';
import { PARAMS_KEY } from '../config/constants';
import Names from '../config/names';
import * as Types from '../types';

interface UseCheckout {
  isDisableField: boolean;
  isLoadingRequest: boolean;
  isLoadingPaymentElement: boolean;
  emailElement: FieldsValue;
  fullNameElement: FieldsValue;
  onSubmitPayment: () => Promise<void>;
  updateFieldFullName: (key: keyof FieldsValue, value: FieldsValue['value']) => void;
  updateFieldEmail: (key: keyof FieldsValue, value: FieldsValue['value']) => void;
  toggleIsLoadingPaymentElement: () => void;
}

interface FieldsValue {
  value: string;
  error: string;
}
const Logger = BugsnagLogger.getInstance();

const DEFAULT_NAME_FIELD: FieldsValue = {
  value: '',
  error: '',
};

const CONFIG_TOAST_ALERT: ToastOptions = {
  toastId: 'payment-form',
};

export const useCheckout = (): UseCheckout => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    information: { email, customerId },
  } = useCtxUser();
  const { showErrorToast } = useCtxToastify();
  const { isTablet, isLaptop } = useBreakpoints();
  const { goldPlus: product, promotionCode, detailPurchase } = useCtxSubscription();

  CONFIG_TOAST_ALERT.position = isTablet || isLaptop ? 'top-right' : 'top-center';

  const [isLoadingPaymentElement, setIsLoadingPaymentElement] = useState<boolean>(true);
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [emailElement, setEmailElement] = useState<FieldsValue>({
    ...DEFAULT_NAME_FIELD,
    value: email,
  });
  const [fullNameElement, setFullNameElement] = useState<FieldsValue>(DEFAULT_NAME_FIELD);

  const toggleIsLoadingPaymentElement = (): void => {
    setIsLoadingPaymentElement(!isLoadingPaymentElement);
  };

  const validationCheckoutForm = async (): Promise<boolean> => {
    if (!elements) {
      return false;
    }

    if (!emailElement.value || !validateIsEmailPattern(emailElement.value)) {
      setEmailElement({ ...emailElement, error: 'Ingresa un correo electrónico válido' });
      return false;
    }

    if (!fullNameElement.value || !validateIsFullNamePattern(fullNameElement.value)) {
      setFullNameElement({ ...fullNameElement, error: 'Ingresa un nombre válido' });
      return false;
    }

    const { error: submitError } = await elements.submit();
    return !submitError;
  };

  const updateFieldEmail = useCallback(
    (key: keyof FieldsValue, value: FieldsValue['value']): void => {
      setEmailElement((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [],
  );

  const updateFieldFullName = useCallback(
    (key: keyof FieldsValue, value: FieldsValue['value']): void => {
      setFullNameElement((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [],
  );

  const fetchCreateSubscriptions = async (): Promise<string> => {
    const paymentParams: Types.ParamsCreatePaymentIntent = {
      currency: `${product?.price.currency}`,
      customerId,
      priceId: `${product?.price.id}`,
      promotionCodeId: promotionCode.id,
    };

    if (!paymentParams.promotionCodeId) {
      delete paymentParams.promotionCodeId;
    }

    const secret = await createSubscriptionClient(paymentParams);
    if (!secret) {
      throw new Error('Not generate client secret');
    }

    return secret;
  };

  const fetchConfirmPayment = async (elements: StripeElements): Promise<void> => {
    const paramsSearch = new URLSearchParams(`?${PARAMS_KEY.email}=${emailElement.value}`);

    Object.entries(detailPurchase).forEach(([key, value]) => {
      paramsSearch.append(key, value);
    });

    const urlPaymentSuccess = `${window.location.origin}${
      Names.paths.thanks
    }?${paramsSearch.toString()}`;
    try {
      setIsLoadingRequest(true);

      const clientSecret = await fetchCreateSubscriptions();

      const response = await stripe?.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          save_payment_method: true,
          receipt_email: emailElement.value,
          return_url: urlPaymentSuccess,
          payment_method_data: {
            billing_details: {
              name: fullNameElement.value,
              email: emailElement.value,
            },
          },
        },
      });

      if (response?.error) {
        Logger.error(
          'The payment could not be confirmed with the server (stripe error) - "useProductGoldPlus"',
          response.error,
        );

        throw new Error(response.error.message);
      }

      Analytics.sendEventCustom({
        category: 'Payment Page',
        action: 'Click',
        label: 'Pago confirmado',
      });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Ocurrió un error al procesar el pago';
      Logger.error(
        'The payment could not be confirmed with the server - "useProductGoldPlus"',
        error,
      );
      showErrorToast(errorMessage, CONFIG_TOAST_ALERT);
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const onSubmitPayment = async (): Promise<void> => {
    const isValid = await validationCheckoutForm();
    if (isValid) {
      await fetchConfirmPayment(elements as StripeElements);
    }
  };

  const isDisableField = isLoadingRequest || isLoadingPaymentElement || !stripe;
  return {
    emailElement,
    fullNameElement,
    onSubmitPayment,
    isDisableField,
    isLoadingRequest,
    isLoadingPaymentElement,
    updateFieldEmail,
    updateFieldFullName,
    toggleIsLoadingPaymentElement,
  };
};
