import type { FC } from 'react';
import Toastify from '../common/toastify';
import User from './user';
import Subscription from './subscription';
import { CtxRoutesProps } from '../../types';

const Contexts: FC<CtxRoutesProps> = ({ children }: CtxRoutesProps): JSX.Element => (
  <Toastify>
    <Subscription>
      <User>{children}</User>
    </Subscription>
  </Toastify>
);

export default Contexts;
