import { useEffect, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Card from '@material-tailwind/react/components/Card';
import CardHeader from '@material-tailwind/react/components/Card/CardHeader';
import CardBody from '@material-tailwind/react/components/Card/CardBody';
import Typography from '@material-tailwind/react/components/Typography';
import { useCtxUser } from '../../../contexts/dish/user';
import { useCtxSubscription } from '../../../contexts/dish/subscription';
import { Analytics } from '../../../controllers';
import Amount from '../../../components/Amount';
import LayoutWithBackground from '../../../components/Layout/Background';
import ProductDescription from '../Payment/Detail/Product/Description';
import { formatAmountForDisplay } from '../../../helpers';
import { PARAMS_KEY } from '../../../config/constants';
import Names from '../../../config/names';
import * as Types from '../../../types';

import './Thanks.css';

const ANALYTICS_PAGE_VIEW: Types.AnalyticsViewPage = {
  page: Names.paths.thanks,
  title: 'Thanks Page',
};

const Thanks: FC = (): JSX.Element => {
  const Navigate = useNavigate();
  const {
    information: { email },
  } = useCtxUser();
  const { goldPlus: product, isLoadingRequest: isLoading } = useCtxSubscription();
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get('payment_intent');
  const emailSendEmail = searchParams.get(PARAMS_KEY.email);
  const paramTotal = searchParams.get(PARAMS_KEY.total) || '0';
  const paramSubtotal = searchParams.get(PARAMS_KEY.subtotal) || '0';
  const paramDiscount = searchParams.get(PARAMS_KEY.discount) || '0';

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);

    if (!paymentIntentId) {
      Navigate(Names.paths.payment);
    }
  }, []);

  const orderId = paymentIntentId?.split('_')[1] || '';
  const subtotal = Number(paramSubtotal);
  const discount = Number(paramDiscount);
  const total = Number(paramTotal);

  const renderDiscount = (): JSX.Element => (
    <>
      <div className='flex justify-between mb-4'>
        <Typography className='font-normal text-sm text-black w-2/5'>Subtotal</Typography>
        <Typography className='flex items-center text-sm font-bold text-black w-2/4'>
          <span className=' text-sm'>{product?.price.currency.toLocaleUpperCase()}</span>
          {formatAmountForDisplay(subtotal, product?.price.currency)}
        </Typography>
      </div>
      <div className='flex justify-between mb-4'>
        <Typography className='font-normal text-sm text-black w-2/5'>Descuento</Typography>
        <Typography className='flex items-center text-sm font-bold text-black w-2/4'>
          <span className=' text-sm'>-{product?.price.currency.toLocaleUpperCase()}</span>
          {formatAmountForDisplay(discount, product?.price.currency)}
        </Typography>
      </div>
    </>
  );

  const renderDataPayment = (): JSX.Element => (
    <Card className='!border-[#F6D588] !border-2 w-11/12 md:w-3/5 lg:min-w-[610px] lg:w-2/4 2xl:w-2/6 items-center'>
      <CardHeader
        floated={false}
        className='flex justify-center w-full bg-[#FFFAF4] m-0 p-6 rounded-b-none shadow-none'
      >
        <img src='/assets/logos/logo-gold-plus.webp' alt='Logo Gold Plus' className='h-14 mb-2' />
      </CardHeader>
      <CardBody className='w-11/12'>
        <Typography className='text-center mt-1 mb-7 text-xl lg:text-2xl font-bold text-[#FF7700] break-all'>
          Operación: {orderId}
        </Typography>
        {isLoading ? (
          <div className='rounded bg-white my-2 animate-pulse'>
            <div className='h-16 bg-gray-200 rounded' />
          </div>
        ) : (
          <div>
            <div className='flex justify-between mb-4'>
              <Typography className='font-bold text-sm text-black w-2/5 mr-1'>
                Paquete SkyAlert GOLD+
              </Typography>
              <Typography className='flex flex-col text-sm !font-thin text-[#A3A3A3] w-2/4'>
                <ProductDescription
                  withLabel={false}
                  styleDescription='mt-2'
                  styleLabel='font-gotham text-base text-black'
                  description={product?.description ?? ''}
                />
              </Typography>
            </div>
          </div>
        )}
        {discount > 0 && renderDiscount()}
        <hr className='border-2 border-zinc-300' />
        {isLoading ? (
          <div className='rounded bg-white mt-2 animate-pulse'>
            <div className='h-16 bg-gray-200 rounded' />
          </div>
        ) : (
          <div className='flex justify-between mt-4'>
            <Typography className='font-bold text-sm text-black w-2/5 mr-1'>Total</Typography>
            <Amount
              className='flex text-base font-bold text-black w-2/4 justify-end'
              price={total}
              currency={product?.price.currency}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );

  return (
    <LayoutWithBackground>
      <LayoutWithBackground.Header>
        <Typography variant='h2' className='text-center text-3xl md:text-4xl font-gotham-bold mb-4'>
          ¡Gracias por suscribirte!
        </Typography>
        <Typography className='text-center text-base font-gotham-bold'>
          Te enviaremos un correo a <span className='underline'>{emailSendEmail ?? email}</span> con
          las indicaciones para que empieces a disfrutar de los beneficios de{' '}
          <span className='text-[#F6D588]'>GOLD</span>+
        </Typography>
      </LayoutWithBackground.Header>
      <LayoutWithBackground.Body>{renderDataPayment()}</LayoutWithBackground.Body>
      <LayoutWithBackground.Footer showCardSocialMedia />
    </LayoutWithBackground>
  );
};

export default Thanks;
