import type { FC } from 'react';
import Button from '@material-tailwind/react/components/Button';
import Dialog, { DialogProps } from '@material-tailwind/react/components/Dialog';
import DialogHeader from '@material-tailwind/react/components/Dialog/DialogHeader';
import DialogBody from '@material-tailwind/react/components/Dialog/DialogBody';
import DialogFooter from '@material-tailwind/react/components/Dialog/DialogFooter';
import Typography from '@material-tailwind/react/components/Typography';
import { mdiEmailCheckOutline, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

interface DialogCanacintraProps {
  isOpen: DialogProps['open'];
  isExistingEmail: boolean;
  onCloseDialog: () => void;
  handleStateDialog: () => void;
}

const DialogCanacintra: FC<DialogCanacintraProps> = ({
  isOpen,
  isExistingEmail,
  onCloseDialog,
  handleStateDialog,
}) => {
  const renderHelp = (): JSX.Element => (
    <>
      <hr className='border-solid border-[1px] w-4/5 my-4' />
      <Typography className='mb-2 !text-center !text-[#000000DE] font-helvetica'>
        ¿Tienes problemas?{' '}
        <a
          className='underline !text-black'
          href='https://wa.me/525579020029?text=Necesito%20ayuda%20con%20la%20alianza%20de%20SkyAlert%20y%20Canacintra'
          onClick={onCloseDialog}
          target='_blank'
          rel='noopener noreferrer'
        >
          Escríbenos para ayudarte
        </a>
      </Typography>
    </>
  );
  return (
    <Dialog open={isOpen} size='md' handler={handleStateDialog} className='px-4 lg:px-10'>
      <button
        type='button'
        className='absolute top-6 !right-6 z-[3] !h-[1.1em] !w-[1.1em]'
        onClick={onCloseDialog}
      >
        <Icon path={mdiClose} size={1} color='#00000085' />
      </button>
      <DialogHeader className='flex justify-center !pb-0'>
        {!isExistingEmail ? (
          <Icon
            path={mdiEmailCheckOutline}
            size={1}
            color='#34A853'
            className='!h-[4em] !w-[4em] lg:!h-[5.5em] lg:!w-[5.5em]'
          />
        ) : (
          <img
            src='/assets/canacintra/mark_email_unread.svg'
            alt='icon email'
            className='!h-[4em] !w-[4em] lg:!h-[5.5em] lg:!w-[5.5em]'
          />
        )}
      </DialogHeader>
      <DialogBody>
        <Typography className='text-3xl lg:text-4xl font-bold mb-2 text-center !text-black font-helvetica'>
          {!isExistingEmail ? '¡Bien hecho!' : '¡Atención!'}
        </Typography>
        <Typography className='text-lg lg:text-xl font-medium mb-4 text-center !text-black font-helvetica'>
          {!isExistingEmail
            ? 'Hemos recibido tu formulario y ahora estás registrado.'
            : 'El correo que enviaste ya ha sido registrado anteriormente.'}
        </Typography>
        <Typography className='mb-4 text-[#00000099] font-extrabold text-xl lg:text-2xl text-center font-helvetica'>
          Paso 2
        </Typography>
        <Typography className='text-lg lg:text-xl  mb-2 text-center !text-[#00000099] font-helvetica'>
          En breve, nos pondremos en contacto contigo a través de correo electrónico. Te
          proporcionaremos las instrucciones necesarias para activar tus cuentas SkyAlert de manera
          eficiente.
        </Typography>
      </DialogBody>
      <DialogFooter className='!flex-col !justify-center'>
        <Button
          type='submit'
          className=' flex justify-center w-full lg:w-auto rounded-xl bg-[#F77711] px-8 py-3 normal-case text-base lg:text-xs lg:text-[1em] font-semibold text-white shadow-sm hover:bg-orange-500 font-helvetica'
          onClick={onCloseDialog}
        >
          {!isExistingEmail ? 'Continuar' : 'Entendido'}
        </Button>
        {renderHelp()}
      </DialogFooter>
    </Dialog>
  );
};

export default DialogCanacintra;
