import * as Types from '../../types';

export const CODE_IS_EXISTING_EMAIL = 418;

export const DEFAULT_CANACINTRA_FORM_DATA: Types.FormDataAllySubscriptions = {
  name: '',
  email: '',
  company: '',
  sector: '',
  phone: '',
  delegation: '',
};

export const DEFAULT_VALUE_WITHOUT_DELEGATION = 'general';

export const DEFAULT_DATA_PARAMS: Types.ParamsDataCanacintra = {
  name: '',
  email: '',
  delegation: DEFAULT_VALUE_WITHOUT_DELEGATION,
};

export const DEFAULT_FIELDS_VALUE: Types.FieldsValue = {
  value: '',
  error: '',
};

export const DEFAULT_FIELDS: Types.FieldsForm = {
  userName: DEFAULT_FIELDS_VALUE,
  email: DEFAULT_FIELDS_VALUE,
  company: DEFAULT_FIELDS_VALUE,
  sector: DEFAULT_FIELDS_VALUE,
  phone: DEFAULT_FIELDS_VALUE,
  delegation: DEFAULT_FIELDS_VALUE,
};

export const OPTIONS_ENTERPRISE: Types.OptionsSelect[] = [
  { value: '', text: 'Seleccionar ...' },
  { value: 'Comercio', text: 'Comercio' },
  { value: 'Construcción', text: 'Construcción' },
  { value: 'Educación', text: 'Educación' },
  { value: 'Fabricación de Material de Transporte', text: 'Fabricación de Material de Transporte' },
  { value: 'Industrias Químicas', text: 'Industrias Químicas' },
  { value: 'Ingeniería Mecánica y Eléctrica', text: 'Ingeniería Mecánica y Eléctrica' },
  { value: 'Minería', text: 'Minería' },
  { value: 'Producción de Metales Básicos', text: 'Producción de Metales Básicos' },
  { value: 'Servicios Públicos', text: 'Servicios Públicos' },
  { value: 'Telecomunicaciones', text: 'Telecomunicaciones' },
  { value: 'Transporte', text: 'Transporte' },
  { value: 'Otro', text: 'Otro' },
];

export const OPTIONS_DELEGATION = [
  { value: '', text: 'Seleccionar ...' },
  { value: 'sede-nacional', text: 'Sede nacional' },
  { value: 'acapulco', text: 'Acapulco' },
  { value: 'agua-prieta', text: 'Aguaprieta' },
  { value: 'aguascalientes', text: 'Aguascalientes' },
  { value: 'apatzingan', text: 'Apatzingan' },
  { value: 'campeche', text: 'Campeche' },
  { value: 'cancun', text: 'Cancun' },
  { value: 'cd-acuna', text: 'CD Acuña' },
  { value: 'cd-carmen', text: 'CD Carmen' },
  { value: 'cd-cuahutemoc', text: 'CD Cuauhtemoc' },
  { value: 'cd-delicias', text: 'CD Delicias' },
  { value: 'cd-juarez', text: 'CD Juarez' },
  { value: 'cd-obregon', text: 'CD Obregon' },
  { value: 'cd-reynosa', text: 'CD Reynosa' },
  { value: 'cd-valles', text: 'CD Valles' },
  { value: 'cd-victoria', text: 'CD Victoria' },
  { value: 'celaya', text: 'Celaya' },
  { value: 'coatzacoalcos', text: 'Coatzacoalcos' },
  { value: 'coahuila-sureste', text: 'Coahuila sureste' },
  { value: 'chetumal', text: 'Chetumal' },
  { value: 'chihuahua', text: 'Chihuahua' },
  { value: 'chilpancingo', text: 'Chilpancingo' },
  { value: 'colima', text: 'Colima' },
  { value: 'cordoba', text: 'Cordoba' },
  { value: 'culiacan', text: 'Culiacan' },
  { value: 'durango', text: 'Durango' },
  { value: 'ensenada', text: 'Ensenada' },
  { value: 'estado-de-mexico', text: 'Estado de Mexico' },
  { value: 'gomez-palacio', text: 'Gómez Palacio' },
  { value: 'guasave', text: 'Guasave' },
  { value: 'hermosillo', text: 'Hermosillo' },
  { value: 'irapuato', text: 'Irapuato' },
  { value: 'jalapa', text: 'Jalapa' },
  { value: 'la-paz', text: 'La paz' },
  { value: 'leon', text: 'Leon' },
  { value: 'los-cabos', text: 'Los cabos' },
  { value: 'los-mochis', text: 'Los Mochis' },
  { value: 'matamoros', text: 'Matamoros' },
  { value: 'mazatlan', text: 'Mazatlán' },
  { value: 'mexicali', text: 'Mexicali' },
  { value: 'minatitlan', text: 'Minatitlan' },
  { value: 'monclova', text: 'Monclova' },
  { value: 'morelia', text: 'Morelia' },
  { value: 'morelos', text: 'Morelos' },
  { value: 'navojoa', text: 'Navojoa' },
  { value: 'nayarit', text: 'Nayarit' },
  { value: 'nogales', text: 'Nogales' },
  { value: 'nuevo-casas-grandes', text: 'Nuevo casas grandes' },
  { value: 'nuevo-laredo', text: 'Nuevo laredo' },
  { value: 'oaxaca', text: 'Oaxaca' },
  { value: 'orizaba', text: 'Orizaba' },
  { value: 'pachuca', text: 'Pachuca' },
  { value: 'parral-hidalgo', text: 'Parral hidalgo' },
  { value: 'piedras-negras', text: 'Piedras negras' },
  { value: 'playa-del-carmen', text: 'Playa del carmen' },
  { value: 'poza-rica', text: 'Poza rica' },
  { value: 'puebla', text: 'Puebla' },
  { value: 'queretaro', text: 'Queretaro' },
  { value: 'rio-bravo', text: 'Rio bravo' },
  { value: 'san-juan-del-rio', text: 'San Juan del rio' },
  { value: 'san-luis-potosi', text: 'San Luis potosi' },
  { value: 'san-luis-rio-colorado', text: 'San Luis rio colorado' },
  { value: 'tabasco', text: 'Tabasco' },
  { value: 'tampico', text: 'Tampico' },
  { value: 'tapachula', text: 'Tapachula' },
  { value: 'tecate', text: 'Tecate' },
  { value: 'tehuacan', text: 'Tehuacan' },
  { value: 'tijuana', text: 'Tijuana' },
  { value: 'tizayuca', text: 'Tizayuca' },
  { value: 'tlaxcala', text: 'Tlaxcala' },
  { value: 'torreon', text: 'Torreon' },
  { value: 'tulancingo', text: 'Tulancingo' },
  { value: 'tuxpan', text: 'Tuxpan' },
  { value: 'tuxtla-gutierrez', text: 'Tuxtla gutierrez' },
  { value: 'uruapan', text: 'Uruapan' },
  { value: 'veracruz', text: 'Veracruz' },
  { value: 'yucatan', text: 'Yucatan' },
  { value: 'zacatecas', text: 'Zacatecas' },
  { value: 'zamora', text: 'Zamora' },
];

export const LIST_PARSE_DELEGATION: Types.ParseDelegation[] = [
  'acapulco',
  'agua-prieta',
  'aguascalientes',
  'apatzingan',
  'campeche',
  'cancun',
  'cd-acuna',
  'cd-carmen',
  'cd-cuahutemoc',
  'cd-delicias',
  'cd-juarez',
  'cd-obregon',
  'cd-reynosa',
  'cd-valles',
  'cd-victoria',
  'celaya',
  'chetumal',
  'chihuahua',
  'chilpancingo',
  'coahuila-sureste',
  'coatzacoalcos',
  'colima',
  'cordoba',
  'culiacan',
  'durango',
  'ensenada',
  'estado-de-mexico',
  'gomez-palacio',
  'guasave',
  'hermosillo',
  'irapuato',
  'jalapa',
  'la-paz',
  'leon',
  'los-cabos',
  'los-mochis',
  'matamoros',
  'mazatlan',
  'mexicali',
  'minatitlan',
  'monclova',
  'morelia',
  'morelos',
  'navojoa',
  'nayarit',
  'nogales',
  'nuevo-casas-grandes',
  'nuevo-laredo',
  'oaxaca',
  'orizaba',
  'pachuca',
  'parral-hidalgo',
  'piedras-negras',
  'playa-del-carmen',
  'poza-rica',
  'puebla',
  'queretaro',
  'rio-bravo',
  'san-juan-del-rio',
  'san-luis-potosi',
  'san-luis-rio-colorado',
  'sede-nacional',
  'tabasco',
  'tampico',
  'tapachula',
  'tecate',
  'tehuacan',
  'tijuana',
  'tizayuca',
  'tlaxcala',
  'torreon',
  'tulancingo',
  'tuxpan',
  'tuxtla-gutierrez',
  'uruapan',
  'veracruz',
  'yucatan',
  'zacatecas',
  'zamora',
];
