import { DEFAULT_VALUE_WITHOUT_DELEGATION, LIST_PARSE_DELEGATION } from '../config/constants';
import { ParseDelegation } from '../types';

export const withoutDiacritics = (text: string) => {
  const diacritics = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç';
  const lettersChange = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc';
  const regex = new RegExp(`[${diacritics}]`, 'ug');

  return text.replace(regex, (match) => lettersChange.charAt(diacritics.indexOf(match)));
};

export const validateDelegationExist = (delegation: string): string =>
  LIST_PARSE_DELEGATION.includes(delegation as ParseDelegation)
    ? delegation
    : DEFAULT_VALUE_WITHOUT_DELEGATION;

export const parseDelegation = (delegationParam: string): string => {
  if (!delegationParam) {
    return DEFAULT_VALUE_WITHOUT_DELEGATION;
  }

  const notSpace = delegationParam.replaceAll(' ', '-');
  const toLowerCaseDelegation = withoutDiacritics(notSpace).toLocaleLowerCase();
  return validateDelegationExist(toLowerCaseDelegation);
};

export const getSearchParam = (key: string, searchParams: URLSearchParams): string => {
  if (!searchParams.has(key)) return '';

  const param = searchParams.get(key) || '';
  const decodeParam = decodeURIComponent(param);
  return decodeParam;
};
