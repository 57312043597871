import Axios from 'axios';
import { skyAlertAPI, skyAlertTokenAPI, skyAlertAllyAPI } from './api';
import { endpointsSkyAlertApi, endpointsAllySubscriptions } from './endpoints';
import { BugsnagLogger } from '../controllers';
import * as Types from '../types';

const Logger = BugsnagLogger.getInstance();

const handleApiErrors = (LoggerMsg: string, error: unknown): string => {
  const isAxiosError = Axios.isAxiosError(error);
  const errorContent = isAxiosError ? error.response?.data : error;

  if (isAxiosError && error.response?.status !== 401) {
    Logger.error(LoggerMsg, errorContent);
  }

  return errorContent?.message || '';
};

const transformQueryParams = (queryParams: Record<string, string>): string => {
  const params = new URLSearchParams(queryParams);
  return params.toString();
};

export const getProducts = async (): Promise<Types.RequestApiSkyAlertProducts['products']> => {
  try {
    const {
      data: { products },
    } = await skyAlertAPI.get<Types.RequestApiSkyAlertProducts>(endpointsSkyAlertApi.products);
    return products;
  } catch (error) {
    handleApiErrors('Error getting products - "getProducts"', error);
    return [];
  }
};

export const getPrices = async (): Promise<Types.RequestApiSkyAlertPrices['prices']> => {
  try {
    const {
      data: { prices },
    } = await skyAlertAPI.get<Types.RequestApiSkyAlertPrices>(endpointsSkyAlertApi.prices);
    return prices;
  } catch (error) {
    handleApiErrors('Error getting prices - "getPrices"', error);
    return [];
  }
};

export const logout = async (): Promise<void> => {
  try {
    await skyAlertTokenAPI.post(endpointsSkyAlertApi.logout);
  } catch (error) {
    const message = handleApiErrors('Error logout - "logout"', error);
    throw new Error(message);
  }
};

export const loginEmail = async (
  email: Types.ParamsLoginEmail['email'],
  password: Types.ParamsLoginEmail['password'],
): Promise<Types.ResponseApiSkyAlertLogin> => {
  try {
    const { data } = await skyAlertAPI.post<Types.ResponseApiSkyAlertLogin>(
      endpointsSkyAlertApi.login,
      { email, password },
    );
    return data;
  } catch (error) {
    const message = handleApiErrors('Error login with email - "loginEmail"', error);
    throw new Error(message);
  }
};

export const validatePromotionCode = async (
  code: string,
): Promise<Types.ResponsePromotionCodes['promotionCode']> => {
  try {
    const queryParams = transformQueryParams({ code });
    const {
      data: { promotionCode },
    } = await skyAlertTokenAPI.get<Types.ResponsePromotionCodes>(
      `${endpointsSkyAlertApi.promotionCodes}?${queryParams}`,
    );
    return promotionCode;
  } catch (error) {
    const message = handleApiErrors('Error promotion code - "promotionCode"', error);
    throw new Error(message);
  }
};

export const signUpEmail = async (
  email: Types.ParamsLoginEmail['email'],
  password: Types.ParamsLoginEmail['password'],
): Promise<Types.ResponseApiSkyAlertLogin> => {
  try {
    const { data } = await skyAlertAPI.post<Types.ResponseApiSkyAlertSignUp>(
      endpointsSkyAlertApi.signUp,
      { email, password },
    );
    return data;
  } catch (error) {
    const message = handleApiErrors('Error sign up with email - "signUpEmail"', error);
    throw new Error(message || 'Ocurrió un error al crear la cuenta');
  }
};

export const refreshAccessToken = async (
  refreshToken: Types.StorageAuthToken['refreshToken'],
): Promise<Types.ResponseRefreshToken['authentication']['accessToken']> => {
  try {
    const {
      data: {
        authentication: { accessToken: newAccessToken },
      },
    } = await skyAlertTokenAPI.post<Types.ResponseRefreshToken>(
      endpointsSkyAlertApi.refreshAccessToken,
      { refreshToken },
    );

    return newAccessToken;
  } catch (error) {
    const message = handleApiErrors('Error refreshing token -  "refreshAccessToken"', error);
    throw new Error(message);
  }
};

export const createSubscriptionClient = async (
  bodyForm: Types.ParamsCreatePaymentIntent,
): Promise<Types.ResponsePaymentIntent['clientSecret']> => {
  try {
    const {
      data: { clientSecret },
    } = await skyAlertTokenAPI.post<Types.ResponsePaymentIntent>(
      `${endpointsSkyAlertApi.subscriptions}`,
      bodyForm,
    );
    return clientSecret;
  } catch (error) {
    const message = handleApiErrors('Error payment intent - "createPaymentIntent"', error);
    throw new Error(message);
  }
};

export const validateSubscription = async (
  emailParam: Types.ParamsSubscriptions,
): Promise<Types.ResponseSubscriptions> => {
  try {
    const email = encodeURIComponent(emailParam);
    const paramsWithQueryMetadata = transformQueryParams({ email });
    const { data } = await skyAlertTokenAPI.get<Types.ResponseSubscriptions>(
      `${endpointsSkyAlertApi.subscriptions}?${paramsWithQueryMetadata}`,
    );
    return data;
  } catch (error) {
    const message = handleApiErrors('Error check subscription - "checkSubscription"', error);
    throw new Error(message);
  }
};

// TODO: REMOVE THIS CODE, ONLY FOR DEMO (Simulate authentication logic)
export const recoveryAccount = async (email: string): Promise<{ message: string }> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ message: `Se ha enviado un correo a "${email}" para recuperar tu contraseña` });
    }, 500);
  });
};

export const sendFormDataAllySubscriptions = async (
  dataForm: Types.FormDataAllySubscriptions,
): Promise<void> => {
  try {
    const parseJsonData = JSON.stringify(dataForm, null, 2);
    await skyAlertAllyAPI.post<Types.ResponseApiSkyAlertLogin>(
      endpointsAllySubscriptions.form,
      parseJsonData,
    );
  } catch (error) {
    const message = handleApiErrors(
      'Error send data form ally subscriptions - "sendFormDataAllySubscriptions"',
      error,
    );
    throw new Error(message);
  }
};
