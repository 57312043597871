import { FALLBACK_PRODUCT_GOLD_PLUS } from '../config/constants';
import { SupportLocales } from '../types';

const supportedLocales: SupportLocales = {
  usd: 'en-US',
  mxn: 'es-MX',
};

const commonNumberFormatOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export function formatAmountForDisplay(
  amount: number | undefined | null,
  currency = 'mxn',
): string {
  const locale = supportedLocales[currency] || 'es-MX';
  const numberFormat = new Intl.NumberFormat(locale, {
    currency,
    ...commonNumberFormatOptions,
  });

  if (Number.isNaN(amount) || !amount) {
    const defaultPrice = FALLBACK_PRODUCT_GOLD_PLUS.price.unitAmount as number;
    return numberFormat.format(defaultPrice / 100);
  }

  const formattedAmount = numberFormat.format(amount / 100);
  return formattedAmount;
}

export function formatAmountForStripe(amount: number, currency: string): number {
  const locale = supportedLocales[currency] || 'es-MX';
  const currencyFormatter = new Intl.NumberFormat(locale, {
    currency,
    ...commonNumberFormatOptions,
  });
  const formattedParts = currencyFormatter.formatToParts(amount);
  const hasDecimal = formattedParts.some((part) => part.type === 'decimal');

  return hasDecimal ? Math.round(amount * 100) : amount;
}
