import type { FC } from 'react';
import { useLanding } from '../../../hooks';
import DialogLogout from '../../../components/Dialog/Logout';
import Logo from '../../../components/Logo';
import Main from './Sections/Main';
import MoreInfo from './Sections/MoreInfo';
import DrawerAuth from './DrawerAuth';
import * as Types from '../../../types';
import names from '../../../config/names';

import './Landing.css';

const ANALYTICS_PAGE_VIEW: Types.AnalyticsViewPage = {
  page: names.paths.landing,
  title: 'Landing Page - Dish',
};

const Landing: FC = (): JSX.Element => {
  const {
    isNormalLogo,
    refContainerMoreInfo,
    isLoading,
    product,
    isOpenMessageLogout,
    handleCloseDialog,
    toggleMessageLogout,
  } = useLanding({
    analytics: ANALYTICS_PAGE_VIEW,
  });

  return (
    <>
      <Logo
        isLogoCollaboration
        variant={isNormalLogo ? 'normal' : 'white'}
        className='hidden  h-[3.5em] 2xl:h-[4.5em] fixed z-[3] m-6 sm:m-12 lg:m-15 2xl:m-20'
      />
      <Main />
      <div ref={refContainerMoreInfo}>
        <MoreInfo loading={isLoading} detail={product} />
      </div>
      <DrawerAuth />
      <DialogLogout
        isOpen={isOpenMessageLogout}
        onCloseDialog={handleCloseDialog}
        handleStateDialog={() => toggleMessageLogout(true)}
      />
    </>
  );
};

export default Landing;
