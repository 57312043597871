import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Analytics, BugsnagLogger } from '../controllers';
import { useAllySubscriptions } from './useAllySubscriptions';
import * as Utils from '../utils';
import * as Constants from '../config/constants';
import * as Types from '../types';

const { DEFAULT_DATA_PARAMS, CODE_IS_EXISTING_EMAIL } = Constants;

type TView = 'form' | 'congratulations';

interface UseLandingCanacintra {
  view: TView;
  userInfo: Types.ParamsDataCanacintra;
  isOpenDialog: boolean;
  isLoadingRequest: boolean;
  hasAccountRegister: boolean;
  handleOnCloseDialog: () => void;
  handleOnSubmitForm: (data: Types.FormDataAllySubscriptions) => Promise<void>;
}

interface ParamsUseLanding {
  analytics?: Types.AnalyticsViewPageCanacintra;
}

const Logger = BugsnagLogger.getInstance();

export const useLandingCanacintra = ({ analytics }: ParamsUseLanding): UseLandingCanacintra => {
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<Types.ParamsDataCanacintra>(DEFAULT_DATA_PARAMS);
  const [view, setView] = useState<TView>('form');
  const [searchParams] = useSearchParams();

  const { sendForm, isLoadingRequest, codeRequest } = useAllySubscriptions();

  const getParamsFromURL = (): void => {
    const dataParams: Types.ParamsDataCanacintra = {
      delegation: Utils.parseDelegation(Utils.getSearchParam('delegation', searchParams)),
      email: searchParams.has('email') ? Utils.getSearchParam('email', searchParams) : '',
      name: searchParams.has('name') ? Utils.getSearchParam('name', searchParams) : '',
    };

    setUserInfo(dataParams);
  };

  const registerAnalytics = (analytics: Types.AnalyticsViewPageCanacintra): void => {
    const {
      page,
      titles: { main, qr },
    } = analytics;
    const title = searchParams.has('qr') ? qr : main;
    Analytics.sendPageView(page, title);
  };

  useEffect(() => {
    if (analytics) {
      registerAnalytics(analytics);
    }

    getParamsFromURL();
  }, []);

  const toggleDialogState = (): void => {
    setIsOpenDialog((prev) => !prev);
  };

  const handleOnSubmitForm = async (data: Types.FormDataAllySubscriptions): Promise<void> => {
    Analytics.sendEventCustom({
      category: 'Canacintra',
      action: 'Click',
      label: 'Submit form',
    });
    try {
      await sendForm(data);
    } catch (error) {
      Logger.error('Could not send form info Canacintra - "handleOnSubmitForm"', error);
    } finally {
      if (!isLoadingRequest) {
        toggleDialogState();
      }
    }
  };

  const handleOnCloseDialog = (): void => {
    const isExistingEmail = codeRequest === CODE_IS_EXISTING_EMAIL;
    const nextView: TView = isExistingEmail ? 'form' : 'congratulations';
    const dialog = nextView === 'form' ? 'user already exists' : 'congratulations';
    Analytics.sendEventCustom({
      category: 'Canacintra',
      action: 'Click',
      label: `close dialog ${dialog}`,
    });
    toggleDialogState();
    setView(nextView);
  };

  return {
    hasAccountRegister: codeRequest === CODE_IS_EXISTING_EMAIL,
    isOpenDialog,
    userInfo,
    view,
    isLoadingRequest,
    handleOnSubmitForm,
    handleOnCloseDialog,
  };
};
