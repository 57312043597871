import { useEffect } from 'react';
import { mdiCheckCircleOutline, mdiApple, mdiGooglePlay } from '@mdi/js';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';
import Icon from '@mdi/react';
import { Analytics } from '../../../controllers';
import names from '../../../config/names';
import * as Types from '../../../types';

const ANALYTICS_PAGE: Types.AnalyticsViewPage = {
  page: names.paths.canacintra,
  title: 'Landing Page CANACINTRA - complete form',
};

const Congratulations = (): JSX.Element => {
  useEffect(() => {
    const { page, title } = ANALYTICS_PAGE;
    Analytics.sendPageView(page, title);
  }, []);

  return (
    <div className='flex justify-center lg:my-4 2xl:mb-10 '>
      <div className='md:w-9/12 lg:w-3/6 2xl:w-4/12 py-10 px-4'>
        <div className='flex flex-col lg:flex-row items-center'>
          <Icon
            path={mdiCheckCircleOutline}
            size={1}
            color='#34A853'
            className='!h-20 !w-20 2xl:!h-[5em] 2xl:!w-[5em] lg:mr-4'
          />
          <Typography className='text-3xl text-center lg:text-start font-[900] font-helvetica'>
            ¡Felicidades por <br className='hidden lg:block' />
            completar tu registro!
          </Typography>
        </div>
        <Typography className='py-6 text-[#00000099] text-lg xl:text-xl text-center lg:text-start font-helvetica'>
          Ahora podrás disfrutar de los beneficios exclusivos que ofrece
          <span className='font-extrabold'> SkyAlert</span> durante un año. Si tienes alguna duda o
          necesitas ayuda, no dudes en ponerte en contacto con tu delegación.
        </Typography>
        <Typography className='2xl:py-6 text-[#000000CC] text-lg xl:text-xl text-center lg:text-start font-helvetica'>
          ¡Gracias por unirte a nuestra alianza estratégica <br className='hidden lg:block' />
          <span className='font-extrabold'>CANACINTRA - SkyAlert!</span>
        </Typography>
        <div className='flex items-center justify-center mt-6 lg:mt-8 xl:mt-8'>
          <img
            src='/assets/canacintra/qr-app.webp'
            className='hidden lg:block w-[8em] h-[8em]'
            alt='QR App'
          />
          <div className='flex flex-col lg:ml-5'>
            <div className='flex items-center lg:ml-4'>
              <img
                src='/assets/canacintra/icon-app.webp'
                className='w-[4em] h-[4em]'
                alt='SkyAlert App'
              />
              <Typography className='text-lg lg:text-xl text-start font-bold ml-2 font-helvetica'>
                Descarga <br />
                SkyAlert App
              </Typography>
            </div>
            <Typography className='lg:hidden text-sm text-center mt-3 font-helvetica'>
              Disponible en:
            </Typography>
            <div className='flex flex-col lg:hidden items-center '>
              <a
                href='https://play.google.com/store/apps/details?id=com.disappster.skyalert&hl=en&gl=US'
                target='_blank'
                rel='noreferrer noopener'
              >
                <Button
                  variant='outlined'
                  className='flex items-center h-14 mt-4 mb-2 w-[10em] gap-1 capitalize text-lg font-helvetica'
                >
                  <Icon path={mdiApple} size={1} color='#000000CC' className='!h-8 !w-8' />
                  App Store
                </Button>
              </a>
              <a
                href='https://apps.apple.com/mx/app/skyalert/id774381416'
                target='_blank'
                rel='noreferrer noopener'
              >
                <Button
                  variant='outlined'
                  className='flex items-center h-14 my-4 w-[10em] gap-1 capitalize text-lg font-helvetica'
                >
                  <Icon path={mdiGooglePlay} size={1} color='#000000CC' className='!h-8 !w-8' />
                  Play Store
                </Button>
              </a>
            </div>
            <Typography className='hidden lg:block text-sm lg:text-md text-center lg:text-start w-[60%] ml-3 mt-4 font-helvetica'>
              Escanea este código QR para descargar nuestra aplicación desde la tienda de
              aplicaciones de tu dispositivo.
            </Typography>
          </div>
        </div>
      </div>
      <img
        src='/assets/canacintra/congratulations.webp'
        className='hidden lg:block max-w-3/6 h-[80vh] my-4'
        alt='SkyAlert | Canacintra'
      />
    </div>
  );
};

export default Congratulations;
