import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ContextDishProvider from '../../contexts/dish';
import Canacintra from '../../routes/Canacintra';
import Thanks from '../../routes/Dish/Thanks';
import Landing from '../../routes/Dish/Landing';
import NotFound from '../../routes/NotFound';
import Terms from '../../routes/Dish/Terms';
import ProtectedRoute from './ProtectedRoute';
import names from '../names';

const Payment = lazy(() => import('../../routes/Dish/Payment'));
const Subscribed = lazy(() => import('../../routes/Dish/Subscribed'));

const DEFAULT_REDIRECT_PAGE_INITIAL = names.paths.landing;

const routes: RouteObject[] = [
  {
    path: names.paths.landing,
    element: (
      <ContextDishProvider>
        <Landing />
      </ContextDishProvider>
    ),
  },
  {
    element: (
      <ContextDishProvider>
        <ProtectedRoute redirectTo={DEFAULT_REDIRECT_PAGE_INITIAL} />
      </ContextDishProvider>
    ),
    children: [
      {
        path: names.paths.payment,
        element: <Payment />,
      },
      {
        path: names.paths.thanks,
        element: <Thanks />,
      },
      {
        path: names.paths.subscribed,
        element: <Subscribed />,
      },
    ],
  },
  {
    path: names.paths.terms,
    element: (
      <ContextDishProvider>
        <Terms />
      </ContextDishProvider>
    ),
  },
  {
    path: names.paths.canacintra,
    element: <Canacintra />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
