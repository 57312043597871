import { useCallback, useState, useEffect } from 'react';
import { useCtxSubscription } from '../contexts/dish/subscription';
import { Analytics } from '../controllers';
import { validatePromotionCode } from '../services';
import * as Types from '../types';

interface UseCoupon {
  discountCode: Types.FieldsValue;
  loadingRequest: boolean;
  isValidDiscountCode: () => boolean;
  updateDiscountCode: (value: string) => void;
  applyPromotionCode: (code: string) => Promise<void>;
  clearStateCoupon: () => void;
}

const MIN_LENGTH_PROMOTION_CODE = 4;

const createDefaultFieldsValue = (): Types.FieldsValue => ({
  value: '',
  error: '',
});

const sendEventInAnalyticsFromPromotionCode = (label: string): void => {
  Analytics.sendEventCustom({
    category: 'Código de promoción',
    action: 'click',
    label,
  });
};

export function useCoupon(): UseCoupon {
  const {
    goldPlus,
    updateStatePromotionCode,
    updateStateDetailPurchase,
    detailPurchase,
    isShowPromotionCode,
  } = useCtxSubscription();
  const [loadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<Types.FieldsValue>(createDefaultFieldsValue);

  useEffect(() => {
    if (!isShowPromotionCode && detailPurchase.discount <= 0) {
      clearStateCoupon();
    }
  }, [isShowPromotionCode]);

  const initialStateDetailPurchase = (): void => {
    updateStateDetailPurchase({
      discount: 0,
      subtotal: goldPlus?.price.unitAmount ?? 0,
      total: goldPlus?.price.unitAmount ?? 0,
    });
  };

  const initialStatePromotionCode = (): void => {
    updateStatePromotionCode({
      id: '',
      code: '',
      discountAmount: 0,
      percent: 0,
    });
  };

  const clearStateCoupon = (): void => {
    setDiscountCode(createDefaultFieldsValue);
    initialStateDetailPurchase();
    initialStatePromotionCode();
  };

  const toggleLoading = (value = false): void => {
    setIsLoadingRequest(value);
  };

  const checkStateLoading = (): void => {
    if (!loadingRequest) {
      toggleLoading(true);
    }
  };

  const applyPromotionCode = async (code: string): Promise<void> => {
    checkStateLoading();
    try {
      const { id, coupon } = await validatePromotionCode(code);
      const { percent_off: percentOff, amount_off: amountOff } = coupon;

      updateStatePromotionCode({
        id,
        code,
        discountAmount: amountOff,
        percent: percentOff,
      });

      if (amountOff) {
        updateStateDetailPurchase({
          ...detailPurchase,
          discount: amountOff,
          total: detailPurchase.subtotal - amountOff,
        });
      }

      if (percentOff) {
        const discount = detailPurchase.subtotal * (percentOff / 100);
        updateStateDetailPurchase({
          ...detailPurchase,
          discount,
          total: detailPurchase.subtotal - discount,
        });
      }
      sendEventInAnalyticsFromPromotionCode('Código aplicado');
    } catch (e) {
      updateErrorDiscountCode('Este código no es válido');
    } finally {
      toggleLoading();
    }
  };

  const updateErrorDiscountCode = (error: string): void => {
    setDiscountCode({ ...discountCode, error });
  };

  const updateDiscountCode = useCallback((value: string): void => {
    setDiscountCode({ error: '', value });
  }, []);

  const isValidDiscountCode = (): boolean => {
    if (!discountCode.value) {
      updateErrorDiscountCode('El código de descuento es requerido');
      return false;
    }

    if (discountCode.value.length < MIN_LENGTH_PROMOTION_CODE) {
      updateErrorDiscountCode('Ingresa un código de descuento válido');
      return false;
    }

    return true;
  };

  return {
    discountCode,
    loadingRequest,
    isValidDiscountCode,
    updateDiscountCode,
    applyPromotionCode,
    clearStateCoupon,
  };
}
