import * as Types from '../types';

export const subscriptionReducer = (
  state: Types.StateCtxSubscription,
  action: Types.TCtxSubscriptionActions,
): Types.StateCtxSubscription => {
  switch (action.type) {
    case Types.EActionsSubscription.SetLoadingRequest: {
      const { isLoadingRequest } = action;
      return {
        ...state,
        isLoadingRequest,
      };
    }
    case Types.EActionsSubscription.SetInformationGoldPlus: {
      const { goldPlus } = action;
      return {
        ...state,
        goldPlus,
      };
    }
    case Types.EActionsSubscription.SetDetailPurchase: {
      const { detailPurchase } = action;
      return {
        ...state,
        detailPurchase,
      };
    }
    case Types.EActionsSubscription.SetPromotionCode: {
      const { promotionCode } = action;
      return {
        ...state,
        promotionCode,
      };
    }
    case Types.EActionsSubscription.SetIsShowPromotionCode: {
      const { isShowPromotionCode } = action;
      return {
        ...state,
        isShowPromotionCode,
      };
    }
    default: {
      const { type } = action;
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
