import type { FC } from 'react';
import Card from '@material-tailwind/react/components/Card';
import Typography from '@material-tailwind/react/components/Typography';
import { Analytics } from '../../controllers';
import SocialNetwork from './SocialNetwork';

import './CardSocialMedia.css';

interface WrapperContainerGradientProps {
  children: JSX.Element;
}

const WrapperContainerGradient = ({ children }: WrapperContainerGradientProps): JSX.Element => (
  <div className='custom-border-gradient rounded-xl mt-8 2xl:mt-12 w-11/12 2xl:w-9/12'>
    {children}
  </div>
);

const CardSocialMedia: FC = (): JSX.Element => {
  const handleOnClickLink = (): void => {
    Analytics.sendEventCustom({
      category: 'Social media',
      action: 'click',
      label: 'Share link page',
    });
  };

  return (
    <WrapperContainerGradient>
      <Card className='p-6 flex flex-col md:flex-row items-center lg:flex-row lg:justify-between'>
        <div className='flex flex-col items-center gap-4 md:flex-row md:items-start lg:w-[65%] 2xl:w-[70%]'>
          <div className='flex justify-center py-2'>
            <img src='/assets/isotype-gold.png' className='w-14 md:w-[100px]' alt='SkyAlert Gold' />
          </div>
          <div className='flex flex-col'>
            <Typography className='text-center text-orange-800 font-gotham-bold text-xl lg:text-2xl md:text-left'>
              ¡Comparte y protege a quienes amas!
            </Typography>
            <Typography className='text-center text-sm text-black font-gotham md:text-left lg:text-md 2xl:text-lg'>
              Para compartir el precio especial de colaborador Dish con tus familiares y amigos,
              solo envíales este link:{' '}
              <a
                className=' text-blue-500 hover:text-blue-700 underline cursor-pointer font-gotham '
                href='https://www.aliados.skyalert.mx/'
                target='_blank'
                rel='noreferrer noopener'
                onClick={handleOnClickLink}
              >
                https://www.aliados.skyalert.mx/
              </a>
            </Typography>
            <Typography className='text-center font-gotham-light mt-8 md:text-left' variant='small'>
              La vigencia de este paquete está sujeta a cambios sin previo aviso.
            </Typography>
          </div>
        </div>
        <div className='flex flex-col mt-4 md:mt-0 md:ml-4'>
          <Typography className='text-sm text-black font-bold text-center'>
            Síguenos en redes sociales
          </Typography>
          <div className='flex w-full items-center justify-center'>
            <SocialNetwork />
          </div>
        </div>
      </Card>
    </WrapperContainerGradient>
  );
};

export default CardSocialMedia;
