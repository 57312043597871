import { Suspense, useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { Analytics } from './controllers';
import LoadingFallback from './components/Loader/Fallback';
import AppRoutes from './config/routes';
import names from './config/names';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();

  const createInstanceAnalyticsByRoute = (route: string) => {
    const { canacintra, landing, thanks, subscribed, payment, recovery, terms } = names.paths;
    const isCanacintraPath = route === canacintra;
    const isDishPath = [landing, thanks, subscribed, payment, terms, recovery].includes(route);

    if (isCanacintraPath) {
      Analytics.getInstance().init('Canacintra');
    }

    if (isDishPath) {
      Analytics.getInstance().init('Dish');
    }
  };

  useEffect(() => {
    createInstanceAnalyticsByRoute(location.pathname);
  }, []);

  const elements = useRoutes(AppRoutes);
  return <Suspense fallback={<LoadingFallback />}>{elements}</Suspense>;
}

export default App;
