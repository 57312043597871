import { useEffect, useState } from 'react';
import type { ToastOptions } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCtxUser } from '../contexts/dish/user';
import { useCtxToastify } from '../contexts/common/toastify';
import { loginEmail, logout, recoveryAccount, signUpEmail } from '../services';
import { Analytics } from '../controllers';
import { validateIsEmailPattern } from '../utils';
import Names from '../config/names';
import * as Types from '../types';

interface UseFormAuthProps {
  defaultView?: Types.ViewAuth;
  isLargeScreen?: boolean;
}

const createDefaultFieldsValue = (): Types.FieldsValue => ({
  value: '',
  error: '',
});

const DEFAULT_FIELDS: Types.FieldsData = {
  login: {
    email: createDefaultFieldsValue(),
    password: createDefaultFieldsValue(),
  },
  recovery: {
    email: createDefaultFieldsValue(),
  },
  signUp: {
    email: createDefaultFieldsValue(),
    password: createDefaultFieldsValue(),
    emailConfirm: createDefaultFieldsValue(),
  },
};

const CONFIG_TOAST_ALERT: ToastOptions = {
  toastId: 'auth-form-login',
};

const MIN_LENGTH_PASSWORD = 8;

const sendEventInAnalyticsFromAuth = (label: string): void => {
  Analytics.sendEventCustom({
    category: 'Landing page - Autenticación',
    action: 'click',
    label,
  });
};

export function useAuthForm({
  defaultView = Types.EViewsAuth.SignUp,
  isLargeScreen = false,
}: UseFormAuthProps) {
  const Navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useCtxToastify();
  const { isOpenDrawerAuth, saveResponseUser, clearInformation } = useCtxUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewForm, setViewForm] = useState<Types.ViewAuth>(defaultView);
  const [fields, setFields] = useState<Types.FieldsData>(DEFAULT_FIELDS);

  CONFIG_TOAST_ALERT.position = isLargeScreen ? 'top-right' : 'top-center';

  useEffect(() => {
    if (!isOpenDrawerAuth) {
      resetFields();
      setViewForm(defaultView);
    }
  }, [isOpenDrawerAuth]);

  const updateField = (
    view: Types.ViewAuth,
    field: Types.FieldsViews,
    value: Types.FieldsValue['value'],
    error: Types.FieldsValue['error'] = '',
  ): void => {
    setFields((prevFields) => ({
      ...prevFields,
      [view]: {
        ...prevFields[view],
        [field]: {
          value,
          error,
        },
      },
    }));
  };

  const resetFields = (): void => {
    setFields(DEFAULT_FIELDS);
  };

  const toggleLoading = (value = false): void => {
    setIsLoading(value);
  };

  const checkStateLoading = (): void => {
    if (!isLoading) {
      toggleLoading(true);
    }
  };

  const isEveryFieldValid = (fieldsCheck: Types.FieldsValue[]): boolean => {
    if (!fieldsCheck) {
      return false;
    }

    return fieldsCheck.every((field) => !field.error);
  };

  const loginWithEmail = async (
    email: Types.ParamsLoginEmail['email'],
    password: Types.ParamsLoginEmail['password'],
  ): Promise<void> => {
    checkStateLoading();
    try {
      const { user, authentication } = await loginEmail(email, password);
      saveResponseUser(user, authentication);
      Navigate(Names.paths.payment, { replace: true });
      sendEventInAnalyticsFromAuth('Inicio de sesión con correo electrónico');
    } catch (e) {
      const error = e as Error;
      // TODO: CHECK WITH BE FOR CODE ERROR
      sendEventInAnalyticsFromAuth('Problemas al iniciar sesión con correo electrónico');
      const customMessage = error.message.includes('credentials')
        ? 'El usuario y/o contraseña son incorrectos'
        : error.message;
      showErrorToast(customMessage, CONFIG_TOAST_ALERT);
    } finally {
      toggleLoading();
    }
  };

  const recoveryPassword = async (email: Types.ParamsLoginEmail['email']): Promise<void> => {
    checkStateLoading();
    try {
      const { message } = await recoveryAccount(email);
      showSuccessToast(message, CONFIG_TOAST_ALERT);
    } catch (e) {
      const error = e as Error;
      showErrorToast(error.message, CONFIG_TOAST_ALERT);
    } finally {
      toggleLoading();
    }
  };

  const signUpWithEmail = async (
    email: Types.ParamsSignUpEmail['email'],
    password: Types.ParamsSignUpEmail['password'],
  ): Promise<void> => {
    checkStateLoading();
    try {
      const { user, authentication } = await signUpEmail(email, password);
      saveResponseUser(user, authentication);
      sendEventInAnalyticsFromAuth('Creación de una cuenta con correo electrónico');
      Navigate(Names.paths.payment, { replace: true });
    } catch (e) {
      const error = e as Error;
      sendEventInAnalyticsFromAuth('Problemas al crear una cuenta con correo electrónico');
      const customMessage = error.message.includes('already registered')
        ? 'El correo que intentas registrar ya se encuentra en uso'
        : error.message;
      showErrorToast(customMessage, CONFIG_TOAST_ALERT);
    } finally {
      toggleLoading();
    }
  };

  const logOutSession = async (): Promise<void> => {
    checkStateLoading();
    try {
      await logout();
      clearInformation();
      Navigate(Names.paths.landing);
      sendEventInAnalyticsFromAuth('Cerrar sesión');
    } catch (e) {
      const error = e as Error;
      showErrorToast(error.message, CONFIG_TOAST_ALERT);
      sendEventInAnalyticsFromAuth('Problemas con cerrar sesión');
    } finally {
      toggleLoading();
    }
  };

  const validateLoginFields = (): boolean => {
    const { email, password } = fields.login;
    if (!email.value) {
      updateField(
        Types.EViewsAuth.Login,
        Types.EFieldsAuth.Email,
        email.value,
        'El correo electrónico es requerido',
      );
      return false;
    }

    if (!password.value) {
      updateField(
        Types.EViewsAuth.Login,
        Types.EFieldsAuth.Password,
        password.value,
        'La contraseña es requerida',
      );
      return false;
    }

    if (!validateIsEmailPattern(email.value)) {
      updateField(
        Types.EViewsAuth.Login,
        Types.EFieldsAuth.Email,
        email.value,
        'El correo electrónico no es válido',
      );
      return false;
    }

    if (!password.value || password.value.length < MIN_LENGTH_PASSWORD) {
      updateField(
        Types.EViewsAuth.Login,
        Types.EFieldsAuth.Password,
        password.value,
        'Debe de incluir un mínimo de 8 caracteres',
      );
      return false;
    }

    return isEveryFieldValid([email, password]);
  };

  const validateRecoveryFields = (): boolean => {
    const { email } = fields.recovery;
    if (!email.value) {
      updateField(
        Types.EViewsAuth.Recovery,
        Types.EFieldsAuth.Email,
        email.value,
        'El correo electrónico es requerido',
      );
      return false;
    }

    if (!validateIsEmailPattern(email.value)) {
      updateField(
        Types.EViewsAuth.Recovery,
        Types.EFieldsAuth.Email,
        email.value,
        'El correo electrónico no es válido',
      );
      return false;
    }

    return isEveryFieldValid([email]);
  };

  const validateSignUpFields = (): boolean => {
    const { email, emailConfirm, password } = fields.signUp;
    if (!email.value) {
      updateField(
        Types.EViewsAuth.SignUp,
        Types.EFieldsAuth.Email,
        email.value,
        'El correo electrónico es requerido',
      );
      return false;
    }

    if (!emailConfirm.value) {
      updateField(
        Types.EViewsAuth.SignUp,
        Types.EFieldsAuth.EmailConfirm,
        emailConfirm.value,
        'El correo electrónico de confirmación es requerido',
      );
      return false;
    }

    if (emailConfirm.value !== email.value) {
      updateField(
        Types.EViewsAuth.SignUp,
        Types.EFieldsAuth.EmailConfirm,
        emailConfirm.value,
        'Los correos electrónicos no coinciden',
      );
      return false;
    }

    if (!validateIsEmailPattern(email.value)) {
      updateField(
        Types.EViewsAuth.SignUp,
        Types.EFieldsAuth.Email,
        email.value,
        'El correo electrónico no es válido',
      );
      return false;
    }

    if (!validateIsEmailPattern(emailConfirm.value)) {
      updateField(
        Types.EViewsAuth.SignUp,
        Types.EFieldsAuth.EmailConfirm,
        emailConfirm.value,
        'El correo electrónico de confirmación no es válido',
      );
      return false;
    }

    if (!password.value || password.value.length < MIN_LENGTH_PASSWORD) {
      updateField(
        Types.EViewsAuth.SignUp,
        Types.EFieldsAuth.Password,
        password.value,
        'Debe de incluir un mínimo de 8 caracteres',
      );
      return false;
    }

    return isEveryFieldValid([email, emailConfirm, password]);
  };

  return {
    fields,
    viewForm,
    isLoading,
    setViewForm,
    updateField,
    resetFields,
    logOutSession,
    signUpWithEmail,
    loginWithEmail,
    recoveryPassword,
    validateLoginFields,
    validateSignUpFields,
    validateRecoveryFields,
  };
}
