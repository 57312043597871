import { type FC } from 'react';
import { Typography } from '@material-tailwind/react';
import SocialMedia from '../CardSocialMedia/SocialNetwork';
import { useBreakpoints } from '../../hooks';
import { Analytics } from '../../controllers';

// TODO: ADD TYPE OF DELEGATION
interface ContactUsItem {
  name: 'Whatsapp' | 'Email' | 'Web';
  link: string;
  text: string;
}

const ListContactUs: ContactUsItem[] = [
  {
    name: 'Web',
    text: 'skyalert.mx',
    link: 'https://skyalert.mx/',
  },
  {
    name: 'Whatsapp',
    text: '55 7902 0029',
    link: 'https://wa.me/5579020029',
  },
  {
    name: 'Email',
    text: 'comercial@skyalert.mx',
    link: 'mailto:comercial@skyalert.mx',
  },
];

export const FooterCanacintra: FC = () => {
  const { windowWidth } = useBreakpoints();
  const currentYear = new Date().getFullYear();
  const isLargeScreen = windowWidth > 1024;

  const handleOnClickContactUs = (name: string): void => {
    Analytics.sendEventCustom({
      category: 'Contact us Canacintra',
      action: 'click',
      label: `Redireccionando a ${name}`,
    });
  };

  return (
    <section className='flex flex-col-reverse lg:flex-row items-center lg:items-start lg:justify-between py-4 lg:py-5 lg:pr-6 lg:pl-8 bg-[#DDD]'>
      <div className='flex flex-col items-center lg:items-start mt-4 lg:mt-0'>
        <img
          src={`/assets/logos/logo-skyalert-canacintra${isLargeScreen ? '-v2' : ''}.webp`}
          className='h-9 lg:h-10 '
          alt='SkyAlert | Canacintra'
        />
        <Typography className='mt-4 text-[#00000099]'>{`© ${currentYear}. Todos los derechos reservados`}</Typography>
      </div>
      <div className='flex flex-col items-center lg:items-end'>
        <div>
          <SocialMedia styleIcon='!h-8 !w-8' />
        </div>
        <div className='flex flex-col lg:flex-row items-center mt-2 flex-wrap text-[#00000099]'>
          {ListContactUs.map(({ name, link, text }, index) => (
            <a
              key={name}
              href={link}
              target='_blank'
              rel='noreferrer noopener'
              onClick={() => handleOnClickContactUs(name)}
              className='flex items-center lg:mr-4 mt-2'
            >
              <Typography className=''>{text}</Typography>
              {index + 1 !== ListContactUs.length && (
                <span className='hidden lg:block ml-4'> | </span>
              )}
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FooterCanacintra;
