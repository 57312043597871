import type { ToastOptions } from 'react-toastify';

export const OPTIONS_TOASTIFY: ToastOptions = {
  position: 'top-right',
  autoClose: 3200,
  progress: undefined,
  theme: 'colored',
};

export const OPTIONS_TOASTIFY_SUCCESS: ToastOptions = {
  type: 'success',
  position: 'top-right',
  autoClose: 4500,
  progress: undefined,
  theme: 'colored',
};

export const OPTIONS_TOASTIFY_INFO: ToastOptions = {
  type: 'info',
  position: 'top-right',
  autoClose: 4500,
  progress: undefined,
  theme: 'colored',
};

export const OPTIONS_TOASTIFY_ERROR: ToastOptions = {
  type: 'error',
  position: 'top-right',
  autoClose: 6000,
  progress: undefined,
  theme: 'colored',
};

export const OPTIONS_TOASTIFY_WARNING: ToastOptions = {
  type: 'warning',
  position: 'top-right',
  autoClose: 6000,
  progress: undefined,
  theme: 'colored',
};
