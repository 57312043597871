import { FC, Fragment } from 'react';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';

interface WrapperMDProps {
  content: string;
  options?: MarkdownToJSX.Options;
}

const WrapperMD: FC<WrapperMDProps> = ({ content, options }): JSX.Element => (
  <Markdown options={options}>{content}</Markdown>
);

WrapperMD.defaultProps = {
  options: {
    wrapper: Fragment,
  },
};

export default WrapperMD;
