import type { FC } from 'react';
import Typography, { type TypographyProps } from '@material-tailwind/react/components/Typography';
import { formatAmountForDisplay, formatInterval } from '../../helpers';
import { FALLBACK_PRODUCT_GOLD_PLUS } from '../../config/constants';
import * as Types from '../../types';

type listOmitProps = 'children' | 'ref';
type customTypographyProps = Omit<TypographyProps, listOmitProps>;

interface AmountProps extends customTypographyProps {
  price?: Types.PriceGoldPlus['unitAmount'];
  currency?: Types.PriceGoldPlus['currency'];
  recurringInterval?: string;
  prefixRecurringInterval?: string;
  classNameRecurringInterval?: string;
}

const Amount: FC<AmountProps> = ({
  price,
  currency,
  recurringInterval,
  prefixRecurringInterval,
  classNameRecurringInterval,
  ...props
}): JSX.Element => {
  return (
    <Typography {...props}>
      {currency?.toUpperCase()}
      {formatAmountForDisplay(price, currency)}
      {!!recurringInterval && (
        <span className={classNameRecurringInterval}>
          {prefixRecurringInterval} <br />
          {formatInterval(recurringInterval)}
        </span>
      )}
    </Typography>
  );
};

Amount.defaultProps = {
  price: FALLBACK_PRODUCT_GOLD_PLUS.price.unitAmount,
  currency: FALLBACK_PRODUCT_GOLD_PLUS.price.currency,
  recurringInterval: '',
  classNameRecurringInterval: '',
  prefixRecurringInterval: '',
};

export default Amount;
