import { type FC, useState, useEffect } from 'react';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';
import { useBreakpoints } from '../../../../../hooks';
import { shortenText } from '../../../../../helpers';

const LIMIT_DESCRIPTION = 100;

interface ProductDescriptionProps {
  description: string;
  limit?: number;
  withLabel?: boolean;
  styleLabel?: string;
  styleDescription?: string;
  styleShowMore?: string;
}

const ProductDescription: FC<ProductDescriptionProps> = ({
  limit = LIMIT_DESCRIPTION,
  description,
  withLabel = false,
  styleLabel = '',
  styleDescription = '',
  styleShowMore = '',
}): JSX.Element => {
  const { isLaptop } = useBreakpoints();
  const [isShowAllDescription, setIsShowAllDescription] = useState<boolean>(true);

  useEffect(() => {
    setIsShowAllDescription(isLaptop);
  }, [isLaptop]);

  const toggleShowDescription = (): void => {
    setIsShowAllDescription((prev) => !prev);
  };

  const textButton = isShowAllDescription ? 'Ver menos' : '... Ver más';

  return (
    <>
      {withLabel ? (
        <Typography variant='h3' className={styleLabel}>
          Descripción
        </Typography>
      ) : null}
      <Typography variant='small' className={styleDescription}>
        {shortenText(description, limit, !isShowAllDescription)}
        <span> </span>
        <Button
          variant='text'
          className={`p-0 normal-case text-blue-600 lg:hidden cursor-pointer inline text-sm active:bg-transparent hover:bg-transparent ${styleShowMore}`}
          onClick={toggleShowDescription}
        >
          {textButton}
        </Button>
      </Typography>
    </>
  );
};

ProductDescription.defaultProps = {
  limit: LIMIT_DESCRIPTION,
  withLabel: false,
  styleLabel: '',
  styleDescription: '',
  styleShowMore: '',
};

export default ProductDescription;
