import { FC, SyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';
import { mdiChevronDoubleLeft } from '@mdi/js';
import { useBreakpoints } from '../hooks';
import { Analytics } from '../controllers';
import Lotties from '../components/Lotties';
import Layout from '../components/Layout';
import names from '../config/names';
import * as Types from '../types';

const ANALYTICS_PAGE_VIEW: Types.AnalyticsViewPage = {
  page: names.paths.notFound,
  title: 'Not Found Page',
};

const NotFound: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { isLaptop } = useBreakpoints();

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);
  }, []);

  const handleOnClickBack = (e: SyntheticEvent): void => {
    e.preventDefault();
    navigate(names.paths.landing);
  };

  const percent = isLaptop ? 0.32 : 0.7;
  const sizeLottie = window.innerWidth * percent;

  return (
    <Layout
      showNavbar={false}
      styleFooter='w-full absolute bottom-0 px-8'
      styleLayout='flex flex-col justify-center items-center p-5 lg:p-10'
    >
      <Lotties animation='NotFound' width={sizeLottie} style={{ zIndex: -1 }} />
      <div className='-mt-10 lg:-mt-14 2xl:-mt-28 z-0 text-center'>
        <Typography variant='h2' color='blue' className='mb-2 text-3xl 2xl:text-4xl'>
          ¡Vaya! No encontramos lo que buscas
        </Typography>
        <Typography variant='lead' className='text-lg 2xl:text-xl'>
          Lo sentimos, la página que buscas no existe o no se encuentra.
        </Typography>
        <Button
          variant='text'
          className='flex text-base 2xl:text-lg m-auto items-center justify-center vertical-center text-blue-600 hover:text-blue-700 mt-4 py-2 px-4'
          onClick={handleOnClickBack}
        >
          <Icon path={mdiChevronDoubleLeft} size={1} />
          Volver al inicio
        </Button>
      </div>
    </Layout>
  );
};

export default NotFound;
