import type { ChangeEvent, ReactNode } from 'react';
import type { ToastOptions } from 'react-toastify';
import type { Stripe } from 'stripe';

type EnvsName =
  | 'bugsnagApiKey'
  | 'skyAlertApiKey'
  | 'skyAlertBaseUrl'
  | 'skyAlertAllyBaseUrl'
  | 'skyAlertAllyApiKey'
  | 'stripeApiKey'
  | 'productIdStripeGoldPlus'
  | 'googleAnalyticsAllyCanacintraApiKey'
  | 'googleAnalyticsAllyDishApiKey';

export type Envs = {
  [key in EnvsName]: string;
};

type EndpointsNameSkyAlert =
  | 'login'
  | 'logout'
  | 'signUp'
  | 'products'
  | 'prices'
  | 'subscriptions'
  | 'refreshAccessToken'
  | 'promotionCodes'
  | 'createPaymentIntent';

export type ApiSkyAlertEndpoints = {
  [key in EndpointsNameSkyAlert]: string;
};

export interface CtxRoutesProps {
  children: ReactNode;
}

export type pathsRoutesNames =
  | 'landing'
  | 'payment'
  | 'terms'
  | 'thanks'
  | 'recovery'
  | 'notFound'
  | 'subscribed'
  | 'canacintra';

export type storageNames = 'user' | 'authToken' | 'cryptoKey';

export type Names = {
  paths: { [key in pathsRoutesNames]: string };
  storage: { [key in storageNames]: string };
  prefixAuth: string;
};

export type StorageAuthToken = AuthenticationAccess;

export type StorageCryptoKey = string;

export type ChangeEventInput = ChangeEvent<HTMLInputElement>;

export interface UserName {
  full?: string;
  first?: string;
  last?: string;
}

export interface UserInformation {
  id: string;
  name?: UserName;
  email: string;
  createdAt: string;
  customerId: string;
}

export interface StateCtxUser {
  isOpenDrawerAuth: boolean;
  information: UserInformation;
}

export interface CtxUser extends StateCtxUser {
  toggleDrawerAuth: () => void;
  updateStateUserInformation: (userInfo: UserInformation) => void;
  saveResponseUser: (user: UserInformation, authentication: AuthenticationAccess) => void;
  clearInformation: () => void;
}

export interface IActionUserSetDrawerState {
  type: EActionsUser.SetDrawerState;
  isOpenDrawerAuth: StateCtxUser['isOpenDrawerAuth'];
}

export interface IActionUserInformationState {
  type: EActionsUser.SetUserInformationState;
  information: StateCtxUser['information'];
}

export type TCtxUserActions = IActionUserSetDrawerState | IActionUserInformationState;

export enum EActionsUser {
  SetDrawerState = 'SET_DRAWER_STATE',
  SetUserInformationState = 'SET_USER_INFORMATION_STATE',
}

export interface StateCtxToastify {
  showToast: (message: string, options?: ToastOptions) => void;
  showSuccessToast: (message: string, options?: ToastOptions) => void;
  showInfoToast: (message: string, options?: ToastOptions) => void;
  showWarningToast: (message: string, options?: ToastOptions) => void;
  showErrorToast: (message: string, options?: ToastOptions) => void;
}

export interface DetailPurchasePromotionCode {
  id: string;
  code: string;
  discountAmount: number | null;
  percent: number | null;
}

export interface SubscriptionDetailPurchase {
  subtotal: number;
  discount: number;
  total: number;
}

export interface StateCtxSubscription {
  isLoadingRequest: boolean;
  isShowPromotionCode: boolean;
  goldPlus: ProductGoldPlus | null;
  detailPurchase: SubscriptionDetailPurchase;
  promotionCode: DetailPurchasePromotionCode;
}

export interface CtxSubscription extends StateCtxSubscription {
  updateStatePromotionCode: (promotionCode: DetailPurchasePromotionCode) => void;
  updateStateDetailPurchase: (detailPurchase: SubscriptionDetailPurchase) => void;
  toggleShowPromotionCode: () => void;
}

export enum EActionsSubscription {
  SetInformationGoldPlus = 'SET_INFORMATION_GOLD_PLUS',
  SetLoadingRequest = 'SET_LOADING_REQUEST',
  SetDetailPurchase = 'SET_DETAIL_PURCHASE',
  SetPromotionCode = 'SET_PROMOTION_CODE',
  SetIsShowPromotionCode = 'SET_IS_PROMOTION_CODE',
}

export interface IActionSubscriptionGoldPlus {
  type: EActionsSubscription.SetInformationGoldPlus;
  goldPlus: StateCtxSubscription['goldPlus'];
}

export interface IActionSubscriptionLoadingRequest {
  type: EActionsSubscription.SetLoadingRequest;
  isLoadingRequest: StateCtxSubscription['isLoadingRequest'];
}

export interface IActionSubscriptionDetailPurchase {
  type: EActionsSubscription.SetDetailPurchase;
  detailPurchase: StateCtxSubscription['detailPurchase'];
}

export interface IActionSubscriptionPromotionCode {
  type: EActionsSubscription.SetPromotionCode;
  promotionCode: StateCtxSubscription['promotionCode'];
}

export interface IActionSubscriptionIsPromotionCode {
  type: EActionsSubscription.SetIsShowPromotionCode;
  isShowPromotionCode: StateCtxSubscription['isShowPromotionCode'];
}

export type TCtxSubscriptionActions =
  | IActionSubscriptionGoldPlus
  | IActionSubscriptionLoadingRequest
  | IActionSubscriptionDetailPurchase
  | IActionSubscriptionPromotionCode
  | IActionSubscriptionIsPromotionCode;

export interface RequestApiSkyAlertProducts {
  products: Stripe.Product[];
}

export interface RequestApiSkyAlertPrices {
  prices: Stripe.Price[];
}

export interface ParamsLoginEmail {
  email: string;
  password: string;
}

export interface ParamsCreatePaymentIntent {
  currency: string;
  customerId: UserInformation['customerId'];
  priceId: ProductGoldPlus['price']['id'];
  promotionCodeId?: string;
}

export type ParamsSignUpEmail = ParamsLoginEmail;

export type ParamsSubscriptions = ParamsLoginEmail['email'];

export interface AuthenticationAccess {
  accessToken: string;
  refreshToken: string;
}

export interface ResponseApiSkyAlertLogin {
  user: UserInformation;
  authentication: AuthenticationAccess;
}

export type ResponseApiSkyAlertSignUp = ResponseApiSkyAlertLogin;

export type ResponseRefreshToken = {
  authentication: Omit<AuthenticationAccess, 'refreshToken'>;
};

export type ResponsePaymentIntent = {
  clientSecret: Stripe.PaymentIntent['client_secret'];
  subscriptionId: Stripe.Subscription['id'];
};

export type ResponsePromotionCodes = {
  promotionCode: Stripe.PromotionCode;
};

export enum ESubscriptionType {
  B2B = 'b2b',
  B2C = 'b2c',
  B2G = 'b2g',
}

export enum ESubscriptionName {
  GOLD = 'gold',
  GOLD_PLUS = 'gold_plus',
  GOLD_FAMILY = 'gold_family',
  GOLD_FAMILY_MEMBER = 'gold_family_member',
  DESK_PLUS = 'desk_plus',
}

export enum ESubscriptionPeriod {
  YEAR = 'year',
  SEMESTER = 'semester',
  MONTH = 'month',
}

enum EMetadataSubscription {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
  BIANNUAL = 'BIANNUAL',
  NONE = 'NONE',
}

export type SubscriptionType = `${ESubscriptionType}`;

export type SubscriptionPeriod = `${ESubscriptionPeriod}`;

export type SubscriptionName = `${ESubscriptionName}`;

type ExcludeSubscriptionName = 'DESK_PLUS' | 'GOLD';

type KeySubscriptionName = Exclude<keyof typeof ESubscriptionName, ExcludeSubscriptionName>;

export type SubscriptionMetadataType = `${EMetadataSubscription}` | KeySubscriptionName;

export interface SubscriptionMetadata {
  subscriptionType: SubscriptionMetadataType;
}

export interface Subscriptions {
  type: SubscriptionType;
  name: SubscriptionName;
  period: SubscriptionPeriod;
  expirationDate: string;
  isActive: boolean;
  metadata: SubscriptionMetadata;
}

export interface ResponseSubscriptions {
  subscriptions: Subscriptions[];
}

export enum EViewsAuth {
  Login = 'login',
  Recovery = 'recovery',
  SignUp = 'signUp',
}

export enum EFieldsAuth {
  Email = 'email',
  EmailConfirm = 'emailConfirm',
  Password = 'password',
}

export type ViewAuth = 'login' | 'recovery' | 'signUp';

export interface FieldsValue {
  value: string;
  error: string;
}

export type FieldsRecovery = 'email';

export type FieldsLogin = 'email' | 'password';

export type FieldsRegister = 'email' | 'emailConfirm' | 'password';

export type FieldsViews = FieldsLogin | FieldsRecovery | FieldsRegister;

export type FieldsData = {
  login: { [key in FieldsLogin]: FieldsValue };
  recovery: { [key in FieldsRecovery]: FieldsValue };
  signUp: { [key in FieldsRegister]: FieldsValue };
};

export type SupportLocales = {
  [key in Stripe.Price['currency']]: string;
};

export type ProductSkyAlert = Stripe.Product;

export type PriceSkyAlert = Stripe.Price;

export type ProductWithPrice = ProductSkyAlert & { attributes: string[]; price: PriceGoldPlus };

export type PriceGoldPlus = {
  id: Stripe.Price['id'];
  unitAmount: Stripe.Price['unit_amount'];
  unitAmountDecimal: Stripe.Price['unit_amount_decimal'];
  currency: Stripe.Price['currency'];
  recurring: Stripe.Price['recurring'];
  type: Stripe.Price['type'];
};

export type ProductGoldPlus = {
  id: Stripe.Product['id'];
  description: Stripe.Product['description'];
  liveMode: Stripe.Product['livemode'];
  name: Stripe.Product['name'];
  metadata: Stripe.Product['metadata'];
  images: Stripe.Product['images'];
  type: Stripe.Product['type'];
  price: PriceGoldPlus;
};

export interface AnalyticsViewPage {
  page: string;
  title: string;
}

export interface AnalyticsViewPageCanacintra {
  page: AnalyticsViewPage['page'];
  titles: {
    main: AnalyticsViewPage['title'];
    qr: AnalyticsViewPage['title'];
  };
}

// ALLY SUBSCRIPTIONS ---------------- //
export interface FormDataAllySubscriptions {
  name: string;
  email: string;
  company: string;
  sector: string;
  phone: string;
  delegation?: string;
}

type EndpointsNameAllySubscriptions = 'form';

export type ApiAllySubscriptionsEndpoints = {
  [key in EndpointsNameAllySubscriptions]: string;
};

// CANACINTRA ---------------------- //

export interface ParamsDataCanacintra {
  name: string;
  email: string;
  delegation: string;
}

export type FieldsNames = 'userName' | 'email' | 'company' | 'sector' | 'phone' | 'delegation';

export type FieldsForm = {
  [key in FieldsNames]: FieldsValue;
};

export interface OptionsSelect {
  value: string;
  text: string;
}

export type ParseDelegation =
  | 'acapulco'
  | 'agua-prieta'
  | 'aguascalientes'
  | 'apatzingan'
  | 'campeche'
  | 'cancun'
  | 'cd-acuna'
  | 'cd-carmen'
  | 'cd-cuahutemoc'
  | 'cd-delicias'
  | 'cd-juarez'
  | 'cd-obregon'
  | 'cd-reynosa'
  | 'cd-valles'
  | 'cd-victoria'
  | 'celaya'
  | 'chetumal'
  | 'chihuahua'
  | 'chilpancingo'
  | 'coahuila-sureste'
  | 'coatzacoalcos'
  | 'colima'
  | 'cordoba'
  | 'culiacan'
  | 'durango'
  | 'ensenada'
  | 'estado-de-mexico'
  | 'gomez-palacio'
  | 'guasave'
  | 'hermosillo'
  | 'irapuato'
  | 'jalapa'
  | 'la-paz'
  | 'leon'
  | 'los-cabos'
  | 'los-mochis'
  | 'matamoros'
  | 'mazatlan'
  | 'mexicali'
  | 'minatitlan'
  | 'monclova'
  | 'morelia'
  | 'morelos'
  | 'navojoa'
  | 'nayarit'
  | 'nogales'
  | 'nuevo-casas-grandes'
  | 'nuevo-laredo'
  | 'oaxaca'
  | 'orizaba'
  | 'pachuca'
  | 'parral-hidalgo'
  | 'piedras-negras'
  | 'playa-del-carmen'
  | 'poza-rica'
  | 'puebla'
  | 'queretaro'
  | 'rio-bravo'
  | 'san-juan-del-rio'
  | 'san-luis-potosi'
  | 'san-luis-rio-colorado'
  | 'sede-nacional'
  | 'tabasco'
  | 'tampico'
  | 'tapachula'
  | 'tecate'
  | 'tehuacan'
  | 'tijuana'
  | 'tizayuca'
  | 'tlaxcala'
  | 'torreon'
  | 'tulancingo'
  | 'tuxpan'
  | 'tuxtla-gutierrez'
  | 'uruapan'
  | 'veracruz'
  | 'yucatan'
  | 'zacatecas'
  | 'zamora';
