import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCtxSubscription } from '../contexts/dish/subscription';
import { useCtxUser } from '../contexts/dish/user';
import { Analytics } from '../controllers';
import names from '../config/names';
import * as Types from '../types';

interface UseLanding {
  product: Types.ProductGoldPlus | null;
  isLoading: boolean;
  isNormalLogo: boolean;
  isOpenMessageLogout: boolean;
  handleCloseDialog: () => void;
  toggleMessageLogout: (value?: boolean) => void;
  refContainerMoreInfo: React.RefObject<HTMLDivElement>;
}

interface ParamsUseLanding {
  analytics?: Types.AnalyticsViewPage;
}

export const useLanding = ({ analytics }: ParamsUseLanding): UseLanding => {
  const location = useLocation();
  const navigate = useNavigate();

  const { goldPlus: product, isLoadingRequest: isLoading } = useCtxSubscription();
  const {
    information: { id: userId },
    isOpenDrawerAuth,
    toggleDrawerAuth,
  } = useCtxUser();

  const refContainerMoreInfo = useRef<HTMLDivElement>(null);

  const [isOpenMessageLogout, setIsOpenMessageLogout] = useState<boolean>(false);
  const [isNormalLogo, setIsNormalLogo] = useState<boolean>(false);

  const handleResize = (): void => {
    setIsNormalLogo(window.scrollY >= window.innerHeight);
  };

  const toggleMessageLogout = (value = false): void => {
    setIsOpenMessageLogout(value);
  };

  const handleCloseDialog = (): void => {
    navigate(location.pathname, { replace: true });
    toggleMessageLogout(false);
    if (!isOpenDrawerAuth) {
      toggleDrawerAuth();
    }
  };

  useEffect(() => {
    if (userId) {
      navigate(names.paths.payment);
    }

    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }

    if (location.state?.redirect) {
      setIsOpenMessageLogout(true);
    }

    window.addEventListener('scroll', handleResize);
    return () => window.removeEventListener('scroll', handleResize);
  }, []);

  return {
    toggleMessageLogout,
    product,
    isLoading,
    isOpenMessageLogout,
    isNormalLogo,
    handleCloseDialog,
    refContainerMoreInfo,
  };
};
