import { ApiAllySubscriptionsEndpoints, ApiSkyAlertEndpoints } from '../types';

const endpointsSkyAlertApi: ApiSkyAlertEndpoints = {
  login: '/auth/login',
  signUp: '/auth/sign-up',
  logout: '/auth/logout',
  refreshAccessToken: '/auth/refresh-access-token',
  products: '/products',
  prices: '/prices',
  subscriptions: '/subscriptions',
  promotionCodes: '/promotion-codes',
  createPaymentIntent: '/payment-intents',
};

const endpointsAllySubscriptions: ApiAllySubscriptionsEndpoints = {
  form: '/subscriptions/ally-create',
};

export { endpointsSkyAlertApi, endpointsAllySubscriptions };
