import { useRef, type FC, type ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBreakpoints } from '../../hooks';
import NavbarCanacintra from './Navbar';
import FooterCanacintra from './Footer';
import { DEFAULT_VALUE_WITHOUT_DELEGATION } from '../../config/constants';
import { getSearchParam, parseDelegation } from '../../utils';

interface LayoutCanacintraProps {
  id?: string;
  children?: ReactNode;
  isShowNavbar?: boolean;
  isShowFooter?: boolean;
  styleLayout?: string;
}

const LayoutCanacintra: FC<LayoutCanacintraProps> = ({
  id,
  children,
  isShowNavbar,
  isShowFooter,
  styleLayout,
}): JSX.Element => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { windowHeight, windowWidth } = useBreakpoints();
  const [isContentBigger, setIsContentBigger] = useState<boolean>(false);
  const [delegationName, setDelegationName] = useState<string>(DEFAULT_VALUE_WITHOUT_DELEGATION);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const contentHeight = contentRef.current?.scrollHeight ?? windowHeight;
    setIsContentBigger(contentHeight > windowHeight);
  }, [windowHeight, windowWidth, contentRef.current]);

  useEffect(() => {
    const delegation = searchParams.has('delegation')
      ? parseDelegation(getSearchParam('delegation', searchParams))
      : DEFAULT_VALUE_WITHOUT_DELEGATION;
    setDelegationName(delegation);
  }, []);

  return (
    <section
      id={id}
      ref={contentRef}
      className={`!font-helvetica helvetica-bold ${
        isContentBigger ? '' : 'lg:h-screen'
      } flex flex-col ${styleLayout ?? ''}`}
    >
      {isShowNavbar && <NavbarCanacintra delegation={delegationName} />}
      {children}
      {isShowFooter && <FooterCanacintra />}
    </section>
  );
};

LayoutCanacintra.defaultProps = {
  id: '',
  children: <div />,
  isShowNavbar: true,
  isShowFooter: true,
  styleLayout: '',
};

export default LayoutCanacintra;
