/* eslint-disable no-param-reassign */
import Axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import { LocalStorage, BugsnagLogger } from '../../controllers';
import { refreshAccessToken } from '../index';
import { getAuthorizationStorage, setAuthorizationStorage } from '../../helpers';
import { configAxios } from '../../config/api';
import History from '../../config/routes/history';
import Names from '../../config/names';
import * as Types from '../../types';

const Logger = BugsnagLogger.getInstance();

const addAuthorizationPrefix = (token: Types.StorageAuthToken['accessToken']): string =>
  `${Names.prefixAuth} ${token}`;

const clearSession = (): void => {
  LocalStorage.clear();
  History.push(Names.paths.landing, {
    redirect: '1',
    type: 'login',
  });
};

const skyAlertTokenAPI: AxiosInstance = Axios.create(configAxios);

skyAlertTokenAPI.interceptors.request.use(
  (config) => {
    const { accessToken } = getAuthorizationStorage();
    if (accessToken) {
      config.headers.Authorization = addAuthorizationPrefix(accessToken);
    }

    return config;
  },
  (error) => Promise.reject(error),
);

let retryRefreshToken = false;

skyAlertTokenAPI.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401 && !retryRefreshToken) {
      retryRefreshToken = true;
      const originalConfigRequest = error.config as AxiosRequestConfig;
      try {
        const { refreshToken } = getAuthorizationStorage();
        const newAccessToken = await refreshAccessToken(refreshToken);

        setAuthorizationStorage({
          accessToken: newAccessToken,
          refreshToken,
        });

        if (!originalConfigRequest.headers) {
          originalConfigRequest.headers = {};
        }

        originalConfigRequest.headers.Authorization = addAuthorizationPrefix(newAccessToken);
        return skyAlertTokenAPI.request(originalConfigRequest);
      } catch (error) {
        Logger.error('Error refreshing token', error);
        clearSession();
      } finally {
        retryRefreshToken = false;
      }
    }

    return Promise.reject(error);
  },
);

export { skyAlertTokenAPI };
