export const shortenText = (textBase: string, limit: number, isShort: boolean): string => {
  if (textBase.length <= limit) {
    return textBase;
  }
  const textShort = textBase.substring(0, limit);
  return isShort ? textShort : textBase;
};

export const isModeDevelop = (hostname = window.location.hostname): boolean => {
  return ['localhost', '127.0.0.1', '', '::1'].includes(hostname);
};

export const isModeStaging = (hostname = window.location.hostname): boolean => {
  return hostname.includes('staging');
};

export const modeStage = (): 'development' | 'staging' | 'production' => {
  if (isModeDevelop()) {
    return 'development';
  }

  return isModeStaging() ? 'staging' : 'production';
};

export const formatInterval = (interval: string): string => {
  const intervalsTranslate: { [key: string]: string } = {
    year: 'año',
    month: 'mes',
    week: 'semana',
    day: 'día',
  };
  if (!interval) {
    return intervalsTranslate.year;
  }

  return intervalsTranslate[interval] ?? intervalsTranslate.year;
};

export const checkIsBrowserMobile = (): boolean => {
  if (navigator.userAgent) {
    const currentBrowser = navigator.userAgent;
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    return toMatch.some((toMatchItem) => currentBrowser.match(toMatchItem));
  }

  return false;
};
