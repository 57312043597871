import { type FC, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '@material-tailwind/react/components/Button';
import Menu from '@material-tailwind/react/components/Menu';
import MenuHandler from '@material-tailwind/react/components/Menu/MenuHandler';
import MenuList from '@material-tailwind/react/components/Menu/MenuList';
import MenuItem from '@material-tailwind/react/components/Menu/MenuItem';
import Typography from '@material-tailwind/react/components/Typography';
import Icon from '@mdi/react';
import { mdiAccountCircleOutline, mdiLoading, mdiLogoutVariant } from '@mdi/js';
import IconButton from '@material-tailwind/react/components/IconButton';
import { useCtxUser } from '../../contexts/dish/user';
import { Analytics } from '../../controllers';
import { useAuthForm, useBreakpoints } from '../../hooks';
import Logo from '../Logo';
import names from '../../config/names';

const Navbar: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { isTablet, isLaptop, isMobile } = useBreakpoints();
  const { isLoading, logOutSession } = useAuthForm({
    isLargeScreen: isTablet || isLaptop,
  });
  const {
    information: { id, email },
  } = useCtxUser();

  const isUser = !!id.length;
  const redirectPath = isUser ? names.paths.payment : names.paths.landing;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnClickLogout = async (): Promise<void> => {
    await logOutSession();
  };

  const handleOnClickLogo = (): void => {
    navigate(redirectPath);
    Analytics.sendEventCustom({
      category: 'Logo',
      action: 'click',
      label: 'Redireccionando al inicio',
    });
  };

  const renderMenu = (): JSX.Element => (
    <Menu
      open={isOpen}
      animate={{
        mount: { y: 0 },
        unmount: { y: 25 },
      }}
      dismiss={{
        outsidePress: false,
        escapeKey: false,
        itemPress: false,
      }}
      placement='bottom-end'
      handler={() => setIsOpen(!isOpen)}
    >
      <MenuHandler className='flex text-[#4D4D4D] border-[#A3A3A3] normal-case'>
        {isMobile ? (
          <IconButton variant='outlined' className='rounded-full !border-[#A3A3A3]'>
            <Icon path={mdiAccountCircleOutline} size={1} />
          </IconButton>
        ) : (
          <Button
            color='gray'
            variant='outlined'
            className='flex text-[0.9em] items-center'
            disabled={isLoading}
          >
            {email}
          </Button>
        )}
      </MenuHandler>
      <MenuList className='p-0'>
        <MenuItem
          onClick={handleOnClickLogout}
          className='flex items-center gap-2 p-3'
          disabled={isLoading}
        >
          <Icon
            path={isLoading ? mdiLoading : mdiLogoutVariant}
            size={1}
            color='gray'
            className={`h-4${isLoading ? ' animate-spin' : ''}`}
          />
          <Typography variant='small' className='font-normal text-[1em] text-gray-600'>
            Cerrar sesión
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <section className='flex justify-between mt-4 mr-6 ml-8'>
      <Logo
        isLogoCollaboration
        className='h-9 lg:h-12 cursor-pointer'
        onClick={handleOnClickLogo}
      />
      {isUser ? renderMenu() : null}
    </section>
  );
};

export default Navbar;
