import React, { FC } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import LottieFiles from '../../config/lotties';

const DEFAULT_OPTIONS: LottieProps['options'] = {
  loop: true,
  autoplay: true,
  animationData: undefined,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type CustomPropsLottie = Omit<LottieProps, 'options'>;

export interface PropsLotties extends CustomPropsLottie {
  animation?: keyof typeof LottieFiles;
  options?: LottieProps['options'];
}

const Lotties: FC<PropsLotties> = ({ animation, options, width, ariaLabel, ariaRole }) => {
  const customOptions = {
    ...DEFAULT_OPTIONS,
    animationData: LottieFiles[animation || 'NotFound'],
  };

  return (
    <Lottie
      options={options?.animationData ? options : customOptions}
      height={width || 300}
      width={width || 300}
      isClickToPauseDisabled
      ariaLabel={ariaLabel || `animation-${animation}`}
      ariaRole={ariaRole || 'presentation'}
    />
  );
};

Lotties.defaultProps = {
  animation: 'NotFound',
  options: {
    animationData: undefined,
  },
};

export default Lotties;
