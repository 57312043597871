import { loadStripe, type Stripe } from '@stripe/stripe-js';
import BugsnagLogger from './BugsnagLogger';
import Envs from '../config/envs';

const Logger = BugsnagLogger.getInstance();

class StripeClient {
  private static instance: StripeClient = new StripeClient();

  private stripe: Stripe | null = null;

  private apiSecret: string;

  private constructor() {
    this.apiSecret = Envs.stripeApiKey;
  }

  public static getInstance(): StripeClient {
    return StripeClient.instance;
  }

  public async init(): Promise<Stripe | null> {
    if (!this.apiSecret) {
      Logger.error('Stripe publish API key not found');
      return null;
    }

    try {
      if (!this.stripe) {
        this.stripe = await loadStripe(this.apiSecret);
      }
    } catch (error) {
      Logger.error('Error loading Stripe', error);
    }

    return this.stripe;
  }
}

export default StripeClient;
