import type { FC, ChangeEvent } from 'react';
import Typography from '@material-tailwind/react/components/Typography';
import { mdiKeyOutline, mdiEmailOutline } from '@mdi/js';
import CustomInput from '../../../../../components/CustomInput';
import WrapperMD from '../../../../../components/WrapperMD';

interface LoginViewProps {
  password: string;
  email: string;
  isLoading?: boolean;
  error: {
    email: string;
    password: string;
  };
  onChangeEmail: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ViewLogin: FC<LoginViewProps> = ({
  email,
  password,
  error,
  isLoading = false,
  onChangeEmail,
  onChangePassword,
}): JSX.Element => (
  <>
    <Typography
      variant='h2'
      className='text-center text-3xl 2xl:text-4xl py-4 px-0 font-gotham-bold'
    >
      Inicia sesión con tu cuenta de SkyAlert
    </Typography>
    <Typography variant='lead' className='text-center text-lg 2xl:text-xl leading-tight'>
      <WrapperMD content="Inicia sesión con un tu cuenta de SkyAlert para continuar con la compra de tu paquete <span className='font-gotham-bold text-[#F6D588]'>**GOLD**</span>**+**" />
    </Typography>
    <div className='flex flex-col mt-6'>
      <CustomInput
        id='email-login'
        type='text'
        label='Correo electrónico'
        placeholder='ejemplo@correo.com'
        pathIcon={mdiEmailOutline}
        isDisable={isLoading}
        error={error.email}
        onChange={onChangeEmail}
        value={email}
      />
      <CustomInput
        id='password-login'
        type='password'
        label='Contraseña'
        placeholder='******'
        styleContainer='mt-6'
        pathIcon={mdiKeyOutline}
        isDisable={isLoading}
        error={error.password}
        onChange={onChangePassword}
        value={password}
      />
    </div>
  </>
);

ViewLogin.defaultProps = {
  isLoading: false,
};

export default ViewLogin;
