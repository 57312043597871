import type { FC } from 'react';
import Typography from '@material-tailwind/react/components/Typography';
import WrapperMD from '../WrapperMD';
import Logo from '../Logo';

interface HeroProps {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
}

const Hero: FC<HeroProps> = ({ title, subtitle, children }): JSX.Element => {
  return (
    <section className='wrapper-hero relative h-screen w-full p-6 sm:p-12 lg:p-15 2xl:p-20 lg:min-h-[535px] 2xl:min-h-[770px]'>
      <div className='flex flex-col justify-between h-full lg:w-3/4'>
        <Logo
          isLogoCollaboration
          variant='white'
          className='block h-[3.5em]  2xl:h-[4.5em]  w-max'
        />
        <div>
          <Typography
            color='white'
            className='mb-4 text-3xl lg:text-4xl 2xl:text-5xl font-gotham-bold'
          >
            <WrapperMD content={title} />
          </Typography>
          <Typography
            color='white'
            className='lg:w-3/4 text-sm lg:text-lg 2xl:text-xl pr-5 !leading-6 2xl:leading-7'
          >
            <WrapperMD content={subtitle} />
          </Typography>
          {children}
        </div>
      </div>
    </section>
  );
};

Hero.defaultProps = {
  children: <div />,
};

export default Hero;
