import {
  type FC,
  type ChangeEvent,
  InputHTMLAttributes,
  useState,
  SyntheticEvent,
  useEffect,
} from 'react';
import Card from '@material-tailwind/react/components/Card';
import Typography from '@material-tailwind/react/components/Typography';
import List from '@material-tailwind/react/components/List';
import ListItem from '@material-tailwind/react/components/List/ListItem';
import { mdiAlertCircleOutline, mdiCheckCircle, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import WrapperMD from '../WrapperMD';
import { LIST_PARSE_DELEGATION } from '../../config/constants';
import * as Types from '../../types';

interface InputCanacintraProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error: string;
  pathIcon: string;
  isDisable: boolean;
  isCorrect: boolean;
  isSelectWithSearch?: boolean;
  options?: Types.OptionsSelect[];
  styleInput?: string;
  styleContainer?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect?: (optionValue: string, inputId: string) => void;
}

export const InputCanacintra: FC<InputCanacintraProps> = ({
  id,
  type,
  label,
  value,
  error,
  pathIcon,
  onChange,
  onChangeSelect,
  isDisable,
  isCorrect,
  isSelectWithSearch,
  styleInput,
  styleContainer,
  options,
  placeholder,
}: InputCanacintraProps) => {
  const [isActiveSelect, setIsActiveSelect] = useState<boolean>(false);
  const [selectText, setSelectText] = useState<string>('');

  useEffect(() => {
    if (type === 'select' && options) {
      setSelectText(options[0].text);
    }
  }, []);

  useEffect(() => {
    // THIS EVALUATION IS TO PASS WHEN THE DELEGATION VALUE IS SHOWN IN THE SELECT
    // TODO: REFACTOR VALIDATIONS
    if (
      id === 'delegation' &&
      type === 'select' &&
      options &&
      value &&
      LIST_PARSE_DELEGATION.includes(value as Types.ParseDelegation) &&
      selectText === options[0].text
    ) {
      let textOption = value as string;
      const delegationInfo = options.filter((option) => option.value === value);
      textOption = delegationInfo[0].text;
      setSelectText(textOption);
    }
  }, [value]);

  const toggleSelectState = (): void => {
    setIsActiveSelect((prev) => !prev);
  };

  const handleSelectOption = (event: SyntheticEvent, value: string, textSelect: string): void => {
    setSelectText(textSelect);
    if (onChangeSelect) {
      onChangeSelect(value, id as string);
    }
    toggleSelectState();
  };

  // TODO: ADD SEARCH INPUT FOR SELECTS
  // const onSearch = (): void => {
  //   const filteredList = options.filter((element: any): any[] => {
  //     const searchValue = value.toString().toLowerCase();
  //     return element[field].toString().toLowerCase().includes(searchValue);
  //   });
  // };

  const renderIcon = (): JSX.Element => (
    <Icon
      path={pathIcon ?? ''}
      size={1}
      color={error ? '#ff1b1b45' : '#00000045'}
      className='absolute top-1/2 left-3 transform -translate-y-1/2 z-[3] !h-[1.1em] !w-[1.1em]'
    />
  );

  const renderInput = (): JSX.Element => (
    <>
      {renderIcon()}
      <input
        id={id}
        name={id}
        value={value}
        disabled={isDisable}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        className={`${styleInput} relative w-full h-[2.7rem] transition px-9 py-2  rounded-xl shadow-sm font-helvetica border border-gray-400/40 bg-white ring-transparent disabled:bg-gray-100 disabled:text-gray-500 placeholder-gray-500 focus:py-[0.45rem] focus:outline-0 focus:border-1 focus:ring-4 focus:!border-[#ff962b80] focus:ring-orange-800/20 focus:ring-orange-800 disabled:shadow-none input-form `}
      />
      {isCorrect && (
        <Icon
          path={mdiCheckCircle}
          size={1}
          color='#34A853'
          className='absolute top-1/2 right-3 transform -translate-y-1/2 z-[3] !h-[1.1em] !w-[1.1em]'
        />
      )}
    </>
  );

  const renderSelect = (): JSX.Element => (
    <div className='flex flex-col'>
      <button
        id={id}
        name={id}
        type='button'
        disabled={isDisable}
        onClick={toggleSelectState}
        className={`${styleInput} relative !w-full h-[2.7rem] transition px-9 py-2 text-start overflow-ellipsis rounded-xl shadow-sm font-helvetica border border-gray-400/40 bg-white ring-transparent disabled:bg-gray-100 disabled:text-gray-500 placeholder-gray-500 focus:py-[0.45rem] focus:outline-0 focus:border-1 focus:ring-4 focus:!border-[#ff962b80] focus:ring-orange-800/20 focus:ring-orange-800 disabled:shadow-none input-form `}
      >
        {renderIcon()}
        {selectText}
        <Icon
          path={isActiveSelect ? mdiChevronUp : mdiChevronDown}
          size={1}
          className='absolute top-1/2 right-3 transform -translate-y-1/2 z-[3] !h-[1.1em] !w-[1.1em]'
        />
      </button>
      <Card
        className={`${
          isActiveSelect ? 'block' : 'hidden'
        } absolute top-[3rem] z-[4] h-40 overflow-scroll overflow-x-hidden`}
      >
        {isSelectWithSearch && (
          <input
            id={id}
            name={id}
            value={value}
            disabled={isDisable}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            className={`${styleInput} relative w-full h-[2.7rem] transition px-9 py-2  rounded-xl shadow-sm font-helvetica border border-gray-400/40 bg-white ring-transparent disabled:bg-gray-100 disabled:text-gray-500 placeholder-gray-500 focus:py-[0.45rem] focus:outline-0 focus:border-1 focus:ring-4 focus:!border-[#ff962b80] focus:ring-orange-800/20 focus:ring-orange-800 disabled:shadow-none input-form `}
          />
        )}
        <List>
          {options &&
            options.map(({ value, text }) => (
              <button
                key={`option-${text}`}
                type='button'
                value={value}
                onClick={(e) => handleSelectOption(e, value, text)}
                className='text-start overflow-ellipsis font-helvetica'
              >
                <ListItem>{text}</ListItem>
              </button>
            ))}
        </List>
      </Card>
    </div>
  );

  return (
    <div className={`mb-4 w-full ${styleContainer}`}>
      <Typography className='font-bold mb-1'>
        <WrapperMD content={label} />
      </Typography>
      <div className='relative z-1'>{type !== 'select' ? renderInput() : renderSelect()}</div>
      {error && (
        <Typography
          variant='small'
          color='red'
          className='mt-2 flex items-center gap-1 font-thin font-helvetica'
        >
          <Icon path={mdiAlertCircleOutline} size={0.75} color='red' />
          {error}
        </Typography>
      )}
    </div>
  );
};

InputCanacintra.defaultProps = {
  options: [],
  styleInput: '',
  styleContainer: '',
  isSelectWithSearch: false,
  onChange: () => null,
  onChangeSelect: () => null,
};

export default InputCanacintra;
