import names from '../config/names';
import { storageNames } from '../types';

class LocalStorage {
  private readonly storage: Storage;

  private readonly keyName: string;

  constructor(keyName: storageNames) {
    this.storage = window.localStorage;
    this.keyName = names.storage[keyName];
  }

  setItem(value: unknown): void {
    if (this.keyName) {
      this.storage.setItem(this.keyName, JSON.stringify(value));
    }
  }

  getItem<T>(otherwise?: T): T | null {
    if (!this.keyName) {
      return null;
    }

    const data: string | null = this.storage.getItem(this.keyName);
    if (data !== null) {
      return JSON.parse(data);
    }

    return otherwise !== undefined ? otherwise : null;
  }

  remove(): void {
    if (this.keyName) {
      this.storage.removeItem(this.keyName);
    }
  }

  static clear(): void {
    window.localStorage.clear();
  }
}

export default LocalStorage;
