import { FC, SyntheticEvent } from 'react';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';
import Logo from '../../../../../components/Logo';
import Amount from '../../../../../components/Amount';
import { ProductGoldPlus } from '../../../../../types';

interface GeneralInfoProps {
  loading: boolean;
  detail: ProductGoldPlus | null;
  onClickSubscribe: (e: SyntheticEvent) => void;
}

const GeneralInfo: FC<GeneralInfoProps> = ({ loading, detail, onClickSubscribe }): JSX.Element => (
  <div className='flex flex-col items-center lg:items-start justify-center lg:w-2/5 '>
    {loading ? (
      <div className='rounded bg-white mb-3.5 animate-pulse'>
        <div className='h-8 w-72 bg-gray-200 rounded' />
      </div>
    ) : (
      <Logo
        isLogoGoldPlus
        src={detail?.images[0]}
        alt={detail?.name}
        className='h-[3.5em] 2xl:h-20 w-auto mb-1'
      />
    )}
    <Typography className='mt-4 text-center lg:text-start text-[#444444] font-gotham-semi-bold !leading-5 2xl:!leading-7 text-lg 2xl:text-2xl'>
      Disfruta del acceso a este beneficio por ser colaborador Dish y recibe un precio especial para
      ti, familiares y amigos.
    </Typography>
    {loading ? (
      <div className='rounded bg-white mt-6 animate-pulse'>
        <div className='h-8 w-72 bg-gray-200 rounded' />
      </div>
    ) : (
      <Amount
        className='mt-6 flex justify-center gap-1 text-lg uppercase font-gotham line-through text-gray-700'
        price={parseInt(detail?.metadata?.initial_price ?? '', 10)}
        currency={detail?.price.currency}
      />
    )}
    {loading ? (
      <div className='rounded bg-white mt-6 animate-pulse'>
        <div className='h-14 w-72 bg-gray-200 rounded' />
      </div>
    ) : (
      <Amount
        className='mt-2 flex justify-center gap-1 text-5xl uppercase font-gotham-bold'
        price={parseInt(detail?.metadata?.current_price ?? '', 10)}
      />
    )}
    <Typography className='mt-4 text-xl'>Precio anualizado. IVA incluido</Typography>
    <Button
      color='white'
      size='sm'
      onClick={onClickSubscribe}
      className='h-14 2xl:h-16 text-lg md:text-xl 2xl:text-2xl text-white mr-2 mb-10 lg:mb-0 normal-case rounded-2xl transition-all mt-6 bg-[#FF7700] hover:shadow-2xl hover:shadow-blue-gray-500/20'
    >
      Suscribirme ahora
    </Button>
  </div>
);

export default GeneralInfo;
