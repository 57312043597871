import { FC } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom';
import { useCtxUser } from '../../contexts/dish/user';

type ProtectedRouteProps = RouteProps & {
  redirectTo: string;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectTo }) => {
  const location = useLocation();
  const {
    information: { id, customerId },
  } = useCtxUser();

  if (!id || !customerId) {
    return <Navigate to={redirectTo} state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
