import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@material-tailwind/react';
import CustomBrowserRouter from './config/routes/CustomBrowserRouter';
import history from './config/routes/history';
import { BugsnagLogger } from './controllers';
import App from './App';

import './GlobalStyles.css';

BugsnagLogger.getInstance().init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ThemeProvider>
    <CustomBrowserRouter history={history}>
      <App />
    </CustomBrowserRouter>
  </ThemeProvider>,
);
