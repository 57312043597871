import { useRef, type FC, type ReactNode, useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useBreakpoints } from '../../hooks';

interface LayoutProps {
  id?: string;
  children?: ReactNode;
  showNavbar: boolean;
  showTerms?: boolean;
  styleFooter?: string;
  styleLayout?: string;
}

const Layout: FC<LayoutProps> = ({
  id,
  children,
  showNavbar,
  styleFooter,
  showTerms,
  styleLayout,
}): JSX.Element => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { windowHeight, windowWidth } = useBreakpoints();
  const [isContentBigger, setIsContentBigger] = useState<boolean>(false);

  useEffect(() => {
    const contentHeight = contentRef.current?.scrollHeight ?? windowHeight;
    setIsContentBigger(contentHeight > windowHeight);
  }, [windowHeight, windowWidth, contentRef.current]);

  return (
    <section
      id={id}
      ref={contentRef}
      className={`gotham-bold ${isContentBigger ? '' : 'h-screen'} flex flex-col ${
        styleLayout ?? ''
      }`}
    >
      {showNavbar && <Navbar />}
      {children}
      <Footer className={styleFooter} showTerms={showTerms} isPositionAbsolute={!isContentBigger} />
    </section>
  );
};

Layout.defaultProps = {
  id: '',
  children: <div />,
  styleFooter: '',
  showTerms: true,
  styleLayout: '',
};

export default Layout;
