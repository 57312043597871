import type { FC, HTMLAttributes, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import Typography from '@material-tailwind/react/components/Typography';
import { Analytics } from '../../controllers';
import packageJson from '../../../package.json';
import names from '../../config/names';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  showTerms?: boolean;
  isPositionAbsolute: boolean;
}

const Footer: FC<FooterProps> = ({
  showTerms,
  isPositionAbsolute,
  className,
  ...props
}): JSX.Element => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleOnClickTerms = (e: SyntheticEvent): void => {
    e.preventDefault();
    navigate(names.paths.terms);
    Analytics.sendEventCustom({
      category: 'Footer',
      action: 'Click',
      label: 'Términos y condiciones',
    });
  };

  return (
    <footer
      {...props}
      className={`flex  ${!showTerms ? 'justify-end' : 'justify-between'} ${
        isPositionAbsolute ? 'absolute' : 'relative'
      } mt-4 w-full bottom-0 px-4 ${className}`}
    >
      {showTerms && (
        <Typography
          variant='small'
          className='underline cursor-pointer'
          onClick={handleOnClickTerms}
        >
          Términos y condiciones
        </Typography>
      )}
      <Typography
        variant='small'
        className='text-center mb-4'
      >{`©SkyAlert ${currentYear} | v${packageJson.version}`}</Typography>
    </footer>
  );
};

Footer.defaultProps = {
  showTerms: true,
};

export default Footer;
