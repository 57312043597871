import * as Types from '../../types';

export const FALLBACK_PRODUCT_GOLD_PLUS: Types.ProductWithPrice = {
  id: '',
  name: 'Paquete SkyAlert GOLD+',
  images: ['/assets/logo-gold-plus.svg'],
  description: 'No disponible',
  object: 'product',
  active: true,
  attributes: [],
  created: 0,
  default_price: '',
  livemode: false,
  features: [],
  metadata: {
    description_large:
      'Incluye 1 suscripción a la aplicación SkyAlert GOLD y 1 licencia SkyAlert HOME para computadora, permitiéndote recibir en ambos dispositivos los siguientes beneficios: alertas sísmicas con tiempo estimado de arribo en tu ubicación, intensidad local (cómo sentirás el sismo) aunque tu teléfono esté en modo "No Molestar", y la posibilidad de agregar hasta 3 ubicaciones GPS.',
    initial_price: '29900',
    current_price: '26900',
  },
  package_dimensions: null,
  shippable: null,
  statement_descriptor: null,
  tax_code: null,
  type: 'service',
  unit_label: null,
  updated: 0,
  url: null,
  price: {
    id: '',
    currency: 'mxn',
    unitAmount: 29900,
    unitAmountDecimal: '29900',
    recurring: {
      aggregate_usage: null,
      interval: 'year',
      interval_count: 1,
      trial_period_days: null,
      usage_type: 'licensed',
    },
    type: 'recurring',
  },
};
