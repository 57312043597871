import { useState } from 'react';
import { isAxiosError } from 'axios';
import { BugsnagLogger } from '../controllers';
import * as Types from '../types';
import { sendFormDataAllySubscriptions } from '../services';

interface UseAllySubscriptions {
  isLoadingRequest: boolean;
  codeRequest: number | null;
  sendForm: (data: Types.FormDataAllySubscriptions) => void;
}

const Logger = BugsnagLogger.getInstance();

export const useAllySubscriptions = (): UseAllySubscriptions => {
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [codeRequest, setCodeRequest] = useState<number | null>(null);

  const sendForm = async (data: Types.FormDataAllySubscriptions): Promise<void> => {
    try {
      setIsLoadingRequest(true);
      await sendFormDataAllySubscriptions(data);
    } catch (error) {
      setCodeRequest(isAxiosError(error) && error.response?.status ? error.response.status : null);
      Logger.error('Could not send form info Canacintra - "useCanacintra"', error);
    } finally {
      setIsLoadingRequest(false);
    }
  };
  return {
    sendForm,
    codeRequest,
    isLoadingRequest,
  };
};
