import type { FC } from 'react';
import Typography from '@material-tailwind/react/components/Typography';
import Icon from '@mdi/react';
import { mdiMicrosoftWindows, mdiApple, mdiLinux } from '@mdi/js';

const DownloadHome: FC = (): JSX.Element => {
  const styleIcon = 'h-8 lg:h-10 2xl:h-12';
  return (
    <div className='flex flex-col'>
      <Typography className='text-center font-semibold mb-2 text-lg md:text-xl lg:text-base 2xl:text-2xl'>
        Disponible para:
      </Typography>
      <div className='flex gap-3'>
        <Icon className={styleIcon} path={mdiMicrosoftWindows} color='#444444' />
        <Icon className={styleIcon} path={mdiApple} color='#444444' />
        <Icon className={styleIcon} path={mdiLinux} color='#444444' />
      </div>
    </div>
  );
};

export default DownloadHome;
